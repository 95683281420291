.MainSlider {
    width: 300px;
    height: 410px;
    padding-top: 10%;
    position: relative;
    overflow: hidden;
}

.arrow-up {
    transform: rotate(-180deg);
}


.arrow-up>.up:hover {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    /* border-bottom: 15px solid transparent; */
    border-top: 15px solid #3865AD;
    transform: rotate(-180deg);
}

.arrow-down>.up-down:hover {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    /* border-bottom: 15px solid transparent; */
    border-top: 15px solid #3865AD;
    transform: rotate(-180deg);
}

/* .arrow-down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid rebeccapurple;
    border-top: 15px solid #3865AD;
} */

.textAlignment {
    padding-top: 4vw !important;
}

.innerSliderBox {
    width: 90%;
    height: 100px;
    margin: 0rem 2rem 2rem 0rem;

}

.sliderData {
    padding-top: 2rem;
}

.innerSliderBox p {
    width: 100%;
    text-decoration: none;
    font-family: 'Lato';
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0.5rem;
    color: #3F4E66;
}

.innerSliderBox .MBom {
    width: 100%;
    margin-left: 0rem;
    padding-top: 0rem;
}

#slide {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s;
}

#slideRight {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s;
}

.slideImg {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slideImgflex {
    width: 50%;

}

.slideImg:hover {
    cursor: pointer;
}

.sliderInner a {
    text-decoration: none;
}

/* .innerSliderBox p:hover {
    font-weight: bold;
} */

.PartM,
.PartMT {
    margin-top: 0.9rem;
}

.innerSliderBox:hover {
    border: double 4px transparent;
    box-shadow: -6px 7px 8px rgba(86, 86, 86, 0.25);
    cursor: pointer;
}

.innerSliderBox:hover p {
    font-weight: bold;
}

#ArrowDown {
    display: none;
    width: 30px;
}

#darkArrow {
    width: 30px;
    margin-left: 2%;
}

/* #ArrowDown:hover{
    display: none;
}
#DownRight{
    display: none;
}
#ArrowDown #DownRight:hover{

    display: block;
} */
/* #DownRightID:hover{
    display: none;
}
#DownRight:hover {
    color: #3F4E66;
} */