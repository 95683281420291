html {
    --section-background-color: linear-gradient(to bottom left,
            rgba(17, 16, 16, 0.582),
            rgba(12, 8, 24, 0.904));

    --image-gradient: linear-gradient(to bottom left,
            rgba(17, 16, 16, 0.678),
            rgba(12, 10, 22, 0.863));

    --imp-text-color: #595959;

}

ul li {
    text-decoration: none;
    list-style: none;
}

.percentage_imgbox_reverse_d {
    display: none;
}

.login-btn {
    padding-top: 3px !important;
    margin-left: 100px !important;
}

.loginNav {
    width: 160px;
    height: 50px;
}

.loginNav p {
    padding-top: 0.6rem;
    color: #3865AD;
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

.login-btn:hover {
    cursor: pointer;
}

.loginNav:hover {
    background: linear-gradient(90deg, #387FF1 10.16%, #CF61CB 128.04%);
    font-weight: 800;
}

.loginNav:hover p {
    color: #fff;
}

.ActionPLm_inner_mobile {
    display: none;
}

.data_mobile {
    display: none;
    position: relative;
    left: 10%;
}

.sliderCard_mobile {
    display: none;
}

.shapeBox_right_mobile {
    display: none;
}

@media (min-width:768px) and (max-width:912px) {

    .navbar {
        padding-left: inherit !important;
    }

    .navbar-nav {
        position: relative;
        right: 10%;
    }

    .home_inner {
        margin-top: 10%;
    }

    .section_what .section_frist p {
        font-size: 120px !important;

    }

    .section_what .section_second P {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    .StyleFont span {
        font-size: 110px !important;
        top: 28% !important;
    }

    .rectangleImg {
        right: 8% !important;
    }

    .dottPoint {
        bottom: 28% !important;
        right: 12% !important;
    }

    .contentBoxInner {
        font-size: 20px !important;
    }

    /* .Main_animation>.row:nth-last-child() {
        margin-left: 30%;
    } */
    .section_benefit .key_benefit p {
        padding: 0rem 10rem;
    }

    .col_homeAbout p {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    .About_Section .AboutImg {
        margin-top: 40% !important;
    }

    .imgStyle .imgCricle .innerStyle {
        width: 130px !important;
        height: 130px !important;
        left: -3px !important;
    }

    .ActionPLM_Home_content .list-group-item div {
        gap: inherit !important;
    }

    .ActionPLM_Home_content .list-group-item div div {
        font-size: 12px !important;
        line-height: 25px !important;
        padding-right: inherit !important;
    }

    .sliderCards {
        left: 9% !important;
    }

    .sliderCards .DiscreteIndustry {
        left: -27% !important;
        top: 33% !important;
    }

    .sliderCards .ProcessIndustry {
        right: 20% !important;
    }

    .CricleImg_industry img,
    .sliderHome img {
        width: 70%;
    }
    .IndustryMain{
        margin-top: 80% !important;
    }
}

@media screen and (max-width:768px) {

    .home_inner {
        height: auto !important;
    }

    .backgroundShap_2nd {
        z-index: -1 !important;
    }

    .salesforceHome {
        margin-top: 4rem;
    }

    .navbar-nav .nav-item :hover::after {
        display: none;
    }

    .keybenfits_mobile {
        margin-top: 50%;
    }

    .shapeBox {
        width: 100px !important;
        position: absolute !important;
        height: 100px !important;
        left: 0;
        top: -10px !important;
        transform: skewX(20deg) !important;
        filter: blur(50px) !important;
        /* width: 724px !important; */
        /* z-index: 999; */
    }

    .shapeBox_right {
        display: none;
    }

    .shapeBox_right_mobile {
        display: block;
        margin-left: 70%;
        top: 8.5% !important;
    }

    .mobileSlider_solution {
        height: 500px;
    }

    .mobileSwiper {
        display: block !important;
        margin-top: 20% !important;
    }

    .home_section {
        width: 100%;
        padding-top: 25%;
        position: inherit !important;
    }

    .home_section h1 {
        font-size: 45px !important;
    }

    /* .home_section p {
        width: 370px;
    } */

    .CardInner {
        width: 270px !important;
        height: 150px !important;
    }

    .customSlider-right .cardslider .CardInner {
        width: 250px !important;
    }

    /* .border-gradient-2nd {
        border-radius: 0px 25px 0px 25px !important;
        border: double 6px transparent !important;
    } */

    .customSlider .swiper {
        height: 230px;
    }

    .Navigation {
        display: none !important;
    }

    .CardInner p {
        padding: 0rem !important;
        font-size: 22px !important;
        margin-top: 1.5rem !important;
    }

    .middelContent .guideText {
        padding-top: 3rem !important;
        padding-left: 0rem !important;
    }

    .customSlider {
        height: 80% !important;
    }

    .backgroundColor {
        height: 80px !important;
        filter: blur(34px) !important;
    }

    /* what Section */
    .section_what {
        display: block !important;
    }

    .section_what .section_frist {
        width: 100% !important;
        height: 400px !important;
        /* border: 2px solid red !important; */
    }

    .section_what .section_second {
        width: 100% !important;
        margin-top: -22% !important;
    }

    .section_what .section_second P {
        padding: 7% !important;
    }

    .section_what .section_frist p {
        font-size: 140px !important;
    }

    .StyleFont span {
        width: 100%;
        font-size: 138px !important;
    }

    .rectangle {
        height: 300px;
    }


    .dottPoint {
        bottom: 22% !important;
        right: 0 !important;
    }

    .col_homeAbout {
        padding: 1rem;
    }

    .section_shapBox {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 99;
    }

    .section_benefit .key_benefit p {
        font-size: 22px !important;
    }

    .slider_middleinner_right .swiper {
        margin-left: inherit !important;
    }

    .imgGaping {
        row-gap: 10vw !important;
    }

    .ActionPLM_Home {
        margin-top: 80% !important;
        height: max-content !important;
    }

    .ActionPLM {
        margin-top: 20% !important;
    }

    .para_bulid {
        font-size: 35px !important;
        line-height: 53px !important;
    }

    .imgStyle .imgCricle,
    .imgStyle .imgCricle .innerStyle {
        width: 220px !important;
        height: 220px !important;
        box-shadow: -20px 20px 15px 5px rgb(0 0 0 / 27%) !important;
    }


    .ActionPLM_inner {
        visibility: hidden;
    }

    .forPC {
        display: none !important;
    }

    .ActionPLM_Home .subbulid_text,
    .Salesforce_Home .subbulid_text {
        padding: 0px !important;
        margin-top: 3rem !important;
        font-size: 18px !important;
        line-height: 27px !important
            /* text-align: left !important; */
    }

    .slideImg {
        width: inherit !important;
    }


    .sliderHome_Section {
        position: relative !important;
        left: 2% !important;
        margin-top: 20% !important;
    }

    .sliderHome .CricleImg_industry {
        height: 190px !important;
        width: 190px !important;
        left: 25% !important;
        top: -40% !important;
    }

    .data_ {
        display: none !important;
    }

    .data_mobile {
        display: block;
    }

    .sliderHome_Section .boxShap {
        display: none !important;
    }

    /* .sliderHome_Section {
        display: none;
    } */

    .ActionPLm_inner_mobile {
        display: block;
        width: 100%;
        height: 1050px;
        position: relative;
    }

    .list-group-item div {
        display: flex;
        justify-content: center;
    }

    .list-group-item div>div {
        width: 88%;
        font-size: 18px !important;
        font-weight: 400;
        color: #595959;
        padding-top: 0.2rem !important;
        text-align: start;
    }

    .list-group-item div img {
        width: 8% !important;
        height: 8% !important;
        margin-top: 0.4rem !important;
    }

    .subText-area {
        width: 100%;
        height: 200px;
        padding-top: 2rem !important;
    }

    .accordingAPI {
        width: 100%;
        height: auto;
        /* border: 2px solid rgba(12, 10, 22, 0.863); */
        position: absolute;
        top: 20;
    }

    .list-group-item {
        border: inherit !important;
        background-color: inherit !important;
    }

    .content_inner {
        border-radius: 35px;
        border: 3px solid #8AB3DC
    }

    .processIndustry .content_inner {
        border: 3px solid #F4E0F4;
    }

    .accordion {
        border-width: none !important;
    }

    .accordion-body {
        padding: 0rem 0rem 0rem 0rem !important;
    }

    .IndustryMain {
        display: none !important;
    }

    .ActionPLM_Home .boxshap {
        display: none !important;
    }

    #progressBar {
        display: none;
    }

    .accordion-item {
        border: none;
        /* margin: 4rem 0rem; */
        border-radius: 50px;
    }

    .accordion-item-frist .discreteIndustry,
    .accordion-item-second .ProcessIndustry {
        width: 90%;
        font-family: Lato;
        font-size: 25px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: center;
        color: #5C90C5;
        border-bottom: 3px solid #8AB3DC;
        margin-left: 5%;
        /* text-decoration: 4px underline#8AB3DC;
        text-underline-offset: 8px; */
    }

    .accordion-item-second .ProcessIndustry {
        border-bottom: 3px solid #F4E0F4;
        margin-top: 2rem;
    }

    .accordion-item-frist .accordion-header button {
        background: #8AB3DC;
        border-radius: 25rem !important;
        margin-top: 0.5rem;
        height: 36.31px;
    }

    .accordion-item-second .accordion-header button {
        background: #F4E0F4;
        border-radius: 25rem !important;
        margin-top: 0.5rem;
        height: 36.31px;
    }

    .accordion-button::after {
        margin-left: 44% !important;
        background-image: url("./Component/image/mobileImages/whitearrow.svg") !important;
    }

    .accordion-button:not(.collapsed) {
        box-shadow: inherit !important;
        background-color: transparent !important;
        color: transparent !important;
        margin-top: -1.9rem !important;
    }

    .accordion-button:not(.collapsed)::after {
        display: block !important;
        background-image: url("./Component/image/mobileImages/brownArrow.svg") !important;
        transform: rotate(0deg);
        position: absolute;
        bottom: -555px;
        left: 0px;
        z-index: 11;
    }

    .accordion-item-second .accordion-button:not(.collapsed)::after {
        display: block !important;
        position: absolute;
        bottom: -450px;
        left: 0px;
        z-index: 11;
    }

    .Industries .subbulid_text {
        font-size: 18px !important;
        padding-bottom: 0% !important;
        line-height: 30px !important;
        /*padding-top: 2rem !important;*/
    }

    .mySwiper .swiper-slide {
        width: 300px;
        height: 130px !important;
        position: relative;
        z-index: 999;
    }

    .swiper-slide a {
        text-decoration: none !important;
    }

    .swiper-slide .contentflex div {
        padding-top: 1rem !important;
        font-size: 18px !important;
    }


    .mySwiper .swiper-pagination-bullets.swiper-pagination-horizontal {
        top: -5px !important;
        left: 9px !important;
    }

    .swiper-pagination-bullet {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        border: 2px solid #888888;
        background-color: transparent !important;
        /* outline: 2px solid #888888; */
    }

    .mysliderMobile .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 70px !important;
    }

    /* .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    } */

    .swiper-pagination-bullet-active {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        border: 2px solid #3865AD;
        background: #3865AD !important;
        /* outline: 2px solid #3865AD !important; */
    }


    .mobileSwiper .discrete p {
        font-family: Lato;
        font-size: 30px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: center;
        color: #5C90C5;
        text-decoration: underline;
        margin-left: 5%;
    }

    /* Footer */

    .Footer {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: normal !important;
    }

    .copyright div {
        font-size: 14px !important;
        padding-top: 1%;
        height: 7.5vw !important;
    }

    .boxShadow {
        height: 180px !important;
    }

    .LogoSection {
        float: left !important;
    }

    .SecondSection,
    .ThridSection {
        width: auto !important;
    }

    .SecondSection .Service-Section {
        padding-left: inherit !important;
    }


    .mobileSlider {
        width: 100%;
        height: 150px;
    }

}

@media (min-width:320px) and (max-width:430px) {

    .home_inner {
        height: auto !important;
    }

    .sliderCard_mobile {
        display: block !important;
    }

    .sliderCards_mobile {
        margin-top: 7% !important;
        height: 600px;
    }

    .innerSliderBox .MBom {
        margin-left: inherit !important;
        padding-top: 0.5rem !important;
    }

    .innerSliderBox {
        width: 70% !important;
        height: 80px !important;
        position: relative;
        left: 44px;
        /* margin-left: 7%; */
    }

    .innerSliderBox p {
        font-size: 18px !important;
        line-height: 30px !important;
    }

    .sliderCards_mobile p {
        font-family: 'Lato';
        font-size: 25px;
        font-weight: 400;
        line-height: 53px;
        letter-spacing: 0em;
        text-align: center;
        color: #5C90C5;
        text-decoration: underline;
        text-underline-offset: 8px;
    }

    .sliderData {
        padding-top: 0rem !important;
    }

    /* .SecondSection,
    .ThridSection {
        width: 100% !important;
    } */

    .ServiceInner ul {
        padding: 0 2rem !important;
    }

    .home_section {
        width: 100%;
        padding-top: 25%;
        position: inherit !important;
    }

    .InputButton {
        padding-right: 9rem !important;
        margin-top: inherit !important;
    }

    .InputButton input {
        width: 280px !important;
        height: 40px !important;
    }

    .InputButton input,
    .InputButton button {
        margin-left: -1rem;
    }


    .InputTag {
        font-size: 15px !important;
        padding-left: 0.9rem !important;
    }

    .tagLine p {
        font-size: 18px !important;
        padding-right: 7rem !important;
        position: relative;
        left: -17px;
    }

    .adj-icons {
        gap: 3.5vw !important;
    }

    .LogoSection {
        padding-bottom: 14% !important;
    }

    .Footer-icons {
        gap: 5.5vw !important;
    }

    .FooterName {
        margin-left: 0rem !important;
    }

    .InputButton button {
        width: 80px !important;
        top: 50% !important;
        right: 33% !important;
        height: 40px !important;
    }

    .home_section h1 {
        font-size: 40px !important;
    }

    .home_section p {
        /* width: 350px;
        padding-right: 3rem; */
        font-size: 20px !important;
    }

    /* .Salesforce_imgs {
        margin-top: 30% !important;
    } */

    .section_what {
        margin-top: 40% !important;
    }

    .login-btn_home {
        margin-top: 5rem !important;
    }

    .col_homeAbout p {
        font-size: 18px !important;
        line-height: 160% !important;
    }

    .section_what .section_frist p {
        font-size: 100px !important;
    }

    .StyleFont span {
        top: 26% !important;
        font-size: 100px !important;
    }

    .dottPoint {
        bottom: 32% !important;
        right: -0.5% !important;
    }

    .section_what .section_second P {
        font-size: 20px !important;
    }

    .section_benefit .key_benefit h2 {
        font-size: 40px !important;
        line-height: 60px !important;
    }

    .section_benefit .key_benefit p {
        font-size: 20px !important;
        padding: 0rem 1rem;
        line-height: 35px !important;
    }

    .marigntopOdd {
        margin-top: inherit !important;
    }

    #per100 {
        position: relative;
        top: -46px;
        left: -60px;
    }

    #per35 {
        position: relative;
        top: 35px;
        left: -45px;
    }

    .border-gradient_50per>p {
        margin-top: 0rem !important;
    }

    /* .Main_animation {
        width: 100% !important;
        background-image: url("./Component/image/Mobileline.svg");
        background-repeat: no-repeat;
        position: relative;
        top: 0;
    } */

    #PER100 {
        width: 100% !important;
        background-image: url("./Component/image/Mobileline.svg");
        background-repeat: no-repeat;
        position: relative;
        top: 90px;
    }

    .percentage_imgbox_reverse {
        display: none !important;
    }

    .percentage_imgbox {
        display: block !important;
    }

    .cricleMain_even:nth-child(2n) {
        top: -3px !important;
        bottom: inherit !important;
        left: 10% !important;
    }

    .contentBoxInner {
        width: 68% !important;
    }

    #per25 {
        position: relative;
        top: 76px;
        left: 20px;
    }

    #per50 {
        position: relative;
        left: 40px;
    }

    .marginTop_MB {
        margin-top: 90%;
    }

    .AboutSection {
        margin-top: 60% !important;
    }

    .sliderCards {
        display: none;
    }

}

@media (min-width:320px) and (max-width:330px) {
    .rectangleImg {
        right: -4% !important;
    }
}

@media (min-width:325px) and (max-width:375px) {

    .sliderData {
        padding-top: 0rem !important;
        padding-left: 10%;
    }

    .rectangleImg {
        right: 3% !important;
    }

    .dottPoint {
        bottom: 32% !important;
        right: 0 !important;
    }

    .percentage_imgbox {
        margin-top: 20% !important;
        position: relative;
        left: -3%;
    }

    .cricleMain {
        left: 19% !important;
        top: -16px !important;
        z-index: 99;
    }

    .cricleMain_even:nth-child(2n) {
        top: 0px !important;
        left: 6% !important;
    }

    .accordion-button:not(.collapsed)::after {
        bottom: -370px !important;
    }

    .accordion-item-second .accordion-button:not(.collapsed)::after {
        bottom: -320px !important;
    }

}

@media (min-width:360px) and (max-width:375px) {
    .cricleMain {
        left: 22% !important;
        top: -9px !important;
        z-index: 99;
    }

    .cricleMain_even:nth-child(2n) {
        left: 12% !important;
    }
}


@media (min-width:375px) and (max-width:430px) {

    .sliderData {
        padding-top: 0rem !important;
        padding-left: 25%;
        /* margin-right: 2%; */
        position: relative;
        right: 13%;
    }

    .home_section p {
        padding-right: 0rem !important;
    }

    .dottPoint {
        right: 4.5% !important;
    }

    .rectangleImg {
        right: 2% !important;
    }

    .percentage_imgbox {
        display: block !important;
        position: relative !important;
        left: -12px !important;
        top: -14px !important;
    }

    .percentage_imgbox_reverse_d {
        display: block !important;
        position: relative !important;
        left: -20px !important;
    }

    .accordion-button:not(.collapsed)::after {
        bottom: -450px !important;
    }

    .accordion-item-second .accordion-button:not(.collapsed)::after {
        bottom: -400px !important;
    }
}

@media (min-width:412px) and (max-width:430px) {

    .cricleMain {
        left: 26% !important;
        top: -6px !important;
        z-index: 99;
    }

    .cricleMain_even:nth-child(2n) {
        left: 10% !important;
        top: -1% !important;
    }

    .contentBoxInner p {
        margin-top: 1.5rem !important;
    }

    .contentBoxInner {
        width: 50% !important;
        font-size: 20px !important;
        line-height: 33px !important;
    }

    .rectangleImg {
        right: 7% !important;
    }

    .dottPoint {
        right: 10% !important;
    }

    .percentage_imgbox {
        top: -30px !important;
    }

    .percentage_imgbox_reverse_d {
        left: -34px !important;
    }

    #PER100 {
        left: 40px;
    }

    #per100 {
        position: relative;
        top: -60px;
        left: -100px;
    }

    #per35 {
        position: relative;
        top: -25px;
        left: -111px;
    }

    #per50 {
        position: relative;
        left: 80px;
        top: -50px;
    }

    .key_benefit h2 {
        padding: 2rem;
    }

    .accordion-button:not(.collapsed)::after {
        bottom: -400px !important;
    }

    .accordion-item-second .accordion-button:not(.collapsed)::after {
        bottom: -350px !important;
    }

    .sliderHome_Section {
        margin-top: 10% !important;
    }

    .InputButton input {
        width: 370px !important;
        height: 40px !important;
    }

    .InputButton input,
    .InputButton button {
        margin-left: -0.6rem;
    }

    .InputButton button {
        width: 80px !important;
        top: 50% !important;
        right: 20% !important;
        height: 40px !important;
    }

}