.Contactus {
    width: 100vw;
    /* padding: 5rem 0rem; */
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.imgSection-contact {
    width: 100%;
    padding: 5rem 0rem;
    background: linear-gradient(178.2deg, #95C3F1 1.29%, rgba(181, 213, 245, 0) 88.97%, rgba(181, 213, 245, 0) 89.93%);
}

.ContentSectionForm {
    width: 100%;
    height: auto;
}

.TilteSection-Form {
    padding-bottom: 5% !important;
}

.TilteSection-Form h3 {
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    font-family: 'Lato';
    color: #5C90C5;
}

.TilteSection-Form h3 span {
    font-weight: 800;
    text-shadow: 1.5px 0px #5C90C5;
}

.TilteSection-Form p {
    font-family: Lato;
    font-size: 16px;
    padding: 0rem 11rem;
    padding-top: 1rem !important;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C5;
}

.ContentSection-contact {
    width: 100%;
    /*border: 2px solid red;
     padding-right: 15%; */
    padding-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactForm {
    width: 60%;
}

.contactForm .form-control {
    border: 1px solid #5C90C5 !important;
    border-radius: 7px !important;
    padding: 1rem !important;

}

.contactForm ::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #C2C2C2;
    padding-left: 0.5rem;
    opacity: 0.7;
}

.contactForm .getInTouch {
    margin-top: 3rem !important;
}
.getInTouch>.Getdemo .loginBtn:hover {
    color: white !important;
}