.sectionplmoneDi {
  width: 100vw;
  height: 60vw;
}

.imgIntegrationDi>p {
  color: #3f4e66;
}

.imgIntegrationDi1 {
  display: flex;
  position: relative;
  padding: 1.8%;
}

.imgIntegrationDi1>img {
  width: 1.3vw;
  left: -7%;
  text-align: start;
  text-align-last: start;
  top: 20%;
  position: absolute;
}

.content-sectionplmtwoDi1 {
  z-index: 1;
  position: relative;
  left: 4%;
  padding: 10% 10%;
  width: 63%;
  font-size: 1.5vw !important;
  text-align: start;
}

.content-sectionplmtwoDi1 {
  z-index: 1;
}

.image-sectionplmone {
  z-index: -1;
}

.black {
  color: #316583 !important;
  position: relative !important;
  left: 15% !important;
}

.purpal {
  color: #4e4e4e !important;
  position: relative !important;
  left: 15% !important;
}

.brown {
  color: #816d57 !important;
  position: relative !important;
  left: 15% !important;
}

.green {
  color: #74806f !important;
  position: relative !important;
  left: 15% !important;
}

.content-sectionplmoneDi {
  width: 67%;

  font-size: 1.5vw;
  justify-content: start;

  position: relative;
}

.content-sectionplmoneDiv>h1 {
  display: flex;
  justify-content: start;
  position: relative;
  left: 9%;
  font-size: 4vw;

  top: 9vw;
}
#classpara1 {
  top: 12% !important;
}
#idparas {
  position: absolute;
  top: 9%;
}
#idparaplm {
  position: absolute;
  top: 15%;
}
#img_icon {
  position: absolute;
  top: 12%;
}
#icon_img {
  position: absolute;
  top: 12%;
}
#id_name {
  position: absolute;
  top: 14%;
}
#id_names {
  position: absolute;
  top: 12%;
}
#classpara_icon {
  position: absolute;
  top: 12%;
}
#class_para {
  position: absolute;
  top: 17%;
}
#id_icon {
  position: absolute;
  top: 14%;
}
#id_paraicon {
  position: absolute;
  top: 15%;
}

.automotive_bg_img {
  z-index: 1;
  width: 100vw;
  height: 60vw;
  background-color: #F4FBFF;

}

.automotive_img {
  z-index: 4;
}

.automotive_bg_img {
  z-index: 10;
}

.automotive {
  z-index: 4 !important;
}

.automotive2 {
  z-index: -4;
}

.automotive_bg_img {
  display: none;
}

.FoodBeverage_img {
  display: none;
}

.automotive_bg_img1 {
  display: none;

}

.Electric {
  display: none;
}

.Manufacturing {
  display: none;
}

.img_Automotive {
  z-index: -4;
}

.img {
  z-index: 9 !important;
  width: 100vw;
  height: 60vw;
  background-color: #F4FBFF;
}

/*@media screen and (max-width: 426px){
  .automotive_img{
    display: none;
  }
  .automotive_bg_img{
    display: block;
    }
    .sectionplmoneDi {
      width: 122vw;
      height: 170vw;
  }
  .content-sectionplmone > h1 {
    display: block;
    justify-content: start;
    position: relative;
    left: -9%;
    font-size: 9vw;
    text-align: start;

  }
  .content-sectionplmoneDi {
    width: 67%;
  
    font-size: 2.5vw;
    justify-content: start;
  
    position: relative;
  }
  .FoodBeverage_img{
    display: block; 
 }
 #Food_Beverage{
  display: none;
 }
 .content-sectionplmone {

  width: 100%;
  font-size: 4vw !important;
  padding: 47% 6%;
}

.image-sectionplmone {
  height: 100vh;
  position: absolute;
}

.automotive_bg_img {
  z-index: 1;
  width: 100vw;
  height: 172vw;
  background-color: #F4FBFF;
}
/*.image-sectionplmone > img {
  width: 139vw;
  height: 100vh;
  position: relative;
}*/
/*.image-sectionplmone > img {
  width: 100vw;
  /*height: 108vh;*/
/* position:relative;
}

.automotive_img {
  z-index: 4;
}
.automotive_bg_img {
  z-index: 10;
}
.automotive {
  z-index: 4 !important;
}

.automotive2 {
  z-index: -4;
}
}*/

@media screen and (min-width:320px)and (max-width: 430px) {

  .sectionplmoneDi {
    height: auto !important;
  }

  .image-sectionplmone {
    top: inherit !important;
  }

  .content-sectionplmoneDiv>h1 {
    font-size: 30px !important;
  }

  .black {
    left: 12% !important;
  }

  .content-sectionplmtwoDi1 {
    left: inherit !important;
    width: 100% !important;
    font-size: inherit !important;
  }

  .imgIntegrationDi1>img {
    width: 24px !important;
    left: -9% !important;
    top: 9% !important;
  }

  #classpara1 {
    top: 6% !important;
  }

  #idparaplm {
    top: 5% !important;
  }

  .industriImgTop {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 999;
  }

  .industriImg {
    position: absolute;
    bottom: 0;
    left: 45%;
    z-index: 999;
  }

  .content-sectionDi>h1 {
    font-size: 30px !important;
  }

  .content-sectionDi {
    margin-top: 1rem !important;
    top: inherit !important;
    /* font-size: 25px !important; */
  }

 .sectionDi {
    height: 100vw;
  }
 
  .imgtext {
    position: relative;
    left: -73%;
    top: -0.5vw;
    text-align: center;
  }

  /*.content-sectionplmtwoDi1{

  .image-sectionplmone > img {
    width: 118%;
    height: 101vh;
    position: absolute;
    z-index: -4 !important;
    top: -9vw;
  }
  .automotive_img {
    display: none;
  }
  .automotive_bg_img {
    display: block;
  }
  .Industrial_Manufacturing {
    display: none;
  }

  .content-sectionplmtwoDi1 {
    width: 91vw !important;
    height: 176vw;
    padding: 10% 10%;
    z-index: 3 !important;
    font-size: 3.5vw !important;
    position: absolute;
    justify-content: center;
    position: absolute;
    top: -39vw;
}*/
  /* .content-sectionplmtwoDi1 {
  width: 91vw !important;
  height: 176vw;
  padding: 10% 10%;
  z-index: 3 !important;
  font-size: 3.5vw !important;
  position: absolute;
  justify-content: center;
  position: absolute;
  top: 25vw;
}

  .black {
    color: #316583 !important;
    position: absolute !important;
    left: 14% !important;
    top: 53% !important;
    font-size: 9.3vw !important;
    font-weight: 390 !important;
  }
  .green {
    color: #74806f !important;
    position: absolute !important;
    left: 14% !important;
    top: 55% !important;
    font-size: 9.3vw !important;
    font-weight: 390 !important;
  }
  .Manufacturing {
    display: block;
  }
  .automotive_bg_img1 {
    display: block;
  }
  .Electric {
    display: block;
  }
  .Electric_vehicles {
    display: none;
  }
  .content-sectionplmoneDiv > h1 {
    display: block !important;
  }
  .purpal {
    color: #4e4e4e !important;
    position: relative !important;
    left: 13% !important;
    top: 20% !important;
    font-size: 9.3vw !important;
    font-weight: 390 !important;
    text-align: start;
  }
  .brown {
    color: #816d57 !important;
    position: absolute !important;
    left: 15% !important;
    top: 23% !important;
    font-size: 9.3vw !important;
    font-weight: 390 !important;
    text-align: start;
  }
}
.green {
  color: #74806f !important;
  position: absolute !important;
  left: 14% !important;
  top: 55% !important;
  font-size: 9.3vw !important;
  font-weight: 390 !important;
}
.Manufacturing {
  display: block;
}
.automotive_bg_img1{
  display: block;
}
.Electric{
  display:block;
}
.Electric_vehicles{
  display:none;
}
.content-sectionplmoneDiv > h1 {
  display: block !important;
}
.purpal {
  color: #4e4e4e !important;
  position: relative !important;
  left: 13% !important;
  top: 20% !important;
  font-size: 9.3vw !important;
  font-weight: 390 !important;
  text-align: start;
  
}
.brown {
  color: #816d57 !important;
  position: absolute !important;
  left: 15% !important;
  top: 23% !important;
  font-size: 9.3vw !important;
  font-weight: 390 !important;
  text-align: start;
  
} */
}

@media screen and (min-width:425px) and (max-width: 768px) {
  /* .mySwiper .swiper-slide
  {
    width: 300px;
    height: 452px !important;
    position: relative;
    z-index: 999;
}*/
  /*.content-sectionplmtwoDi1 {
  z-index: 1;
  position: absolute;
  left: 4%;
  padding: 10% 10%;
  width: 63%;
  font-size: 1.5vw !important;
  text-align: start;
  height: 100vh !important;
}
.image-sectionplmone {
  width: 116%;
  height: 100vh !important;
  position: absolute;
}*/
  /*.mySwiper .swiper-slide {
  width: 300px;
  height: 468px !important;
  position: relative;
  z-index: 999;
}*/
/*.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: inherit;
  list-style: none;
  height: 57vh;
  z-index: 1;
}*/
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 57vh;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
/*.swiper-button-next, .swiper-button-prev {
  top: 39% !important;
}*/
}
