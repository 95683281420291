.Product_Group {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vw;
}
.Group_crcle,
.Group_w,
.Group_bell {
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Group_crcle > p,
.Group_w > p,
.Group_bell > p {
  width: 65%;
  font-size: 1.5vw;
  font-family: "Lato";
  color: #3f4e66;
  padding: 1%;
  
}
.Vector_one {
 padding: 8% !important;
 

}
#hdevector {
  display: none;
}
#groupbellicon{
  position: relative;
  top: 0%;
}

#Document_img{
  position: relative;
  top: 3%;
  
}
#group_bell_img{
  position: relative;
  top:-3%;
}
#groupE_img{
position: relative;
top:3%;
}
#Groups_bell_img{
  position: relative;
  top: 3%;
}
#img_grouptwo{
  position: relative;
  top: -4%;
}

#bomConfi>.Group_w{
  margin-bottom: 2.3%;
}
@media screen and (max-width: 426px) {
.Product_Group {
  display: block;
  position: relative;
  left: 30%;
  padding: 1%;
  height: 100vh;
}

.Vector_one {
  padding: 24% !important;
}

.Group_crcle,
.Group_w,
.Group_bell{
  padding: 4%;
}
.Group_crcle > p,
.Group_w > p,
.Group_bell > p {
  width: 40vw !important;
  font-size: 4vw;

}
}
@media screen and (min-width:320px)and (max-width: 426px) {
  .Product_Group {
    display: block;
    position: relative;
    left: 27%;
    padding: 13%;
    height: 112vh;
}
#IMG_infoicon{
  position: relative;
  top:0%;
}
#group_img{
  position: relative;
  top:-1%;
}
#IMG_management{
  position: relative;
  top: -1%;
}
#IMG_file{
  position: relative;
  top: 10%;
}
}