@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300&family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  padding: 0;
  margin: 0;
}
.section {
  width: 100%;
  height: 45vw;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-section {
  width: 60%;
  padding: 10% 10%;
  text-align: center;
  font-size: 1.5vw;
}
.content-section > h1 {
  font-size: 3.3vw;
  font-weight: 400;
  margin-bottom: 5%;
  color: #5c90c4;
  text-align: center;
  text-shadow: 1px 0px #5c90c4;
}
.boldSpan {
  font-weight: 900;
}
.image-section {
  width: 40%;
}
.image-section > img {
  width: 100%;
}

.content-section > p {
  color: #3f4e66;
  text-align: center;
  width: 100%;
  font-size: 1.5vw;
}
#imgchangemag {
  width: 30% !important;
  
}
#bomConfigurationMobile{
  width: 40% !important;
}
#imgchangemag1 {
  width: 34% !important;
  position: relative;
  left: 3%;
  
}
#imgchangemag2 {
  width: 29%;
  position: relative;
  left: 6%;
}
#requirement_text{
  display: none;
}
#changemanagment_img{
  display: none;
}
/*#changemanagment_img1{
display:none ;
}*/
@media (max-width: 426px){
  #bomConfigurationMobile {
    display: none;
}
}
@media screen and (min-width:320px)and (max-width: 426px) {
  
  .section{
    width: 100%;
    height: 100vw;
    display: block;
  }
.content-section {
  width: 100%;
  text-align: center;

  padding:  10% 10%;


}

.content-section > h1 {
  font-size: 9vw;
  

}
.content-section > p{
  font-size: 4.9vw !important;
  width: 110% !important;
  position: relative;
  left: -5%;
  top: 3%;
}
  .image-section {
    width: 80%;
    
  }

.image-section>img{
    width: 100%;
   
  }
  #requirement_text{
    display: block;
  }
  #changemanagment_img{
    display: block;
    padding: 7% 0% 15% 12%;
  }

  #change_managment_img{
    display: none;
  }
  #imgchangemag{
    display: none;
  }
  #imgchangemag1{
display: none;
  }
  #imgchangemag2{
    display: none;
  }
}