.contentdiv {
  width: 100%;
  height: 45vw;
  text-align: center;
}
.sectionplmthree {
  width: 100vw;
  color: #8288c2;
  font-size: 2vw;
  position: relative;
  top: 28%;
}

.contentimgss {
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contentmg {
  width: 18vw;
  display: flex;
  justify-content: space-around;

  align-items: center;
  padding: 8.5% 0%;
}
.contentimgOTHERS {
  width: 100vw;
}
.contentmg1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99vw;
  padding: 2% 0% 0% 3%;
}
.imgIntegration1 > img {
  width: 20vw;
  position: absolute;
  top: 35%;
}
.imgIntegration2 > img {
  width: 17vw;
}
.imgIntegration2 {
  width: 19vw;
  height: 10vw;
}
.title1 {
  position: relative !important;
  left: -8.8% !important;
}
#text_div {
  display: none;
}
@media screen and (max-width: 426px) {
  .sectionplmthree {
    width: 99vw;

    font-size: 2.3vw;
  }
  .contentmg {
    position: absolute;
    top: 50%;
  }
}

@media screen and (min-width: 320px) and (max-width: 426px) {
  .imgIntegration2 {
    width: 54vw;
    height: 65vw;
  }
  .contentimgss {
    width: 60vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .contentimgOTHERS {
    width: 100vw;
  }
  .contentmg {
    position: absolute;
    width: 18vw;
    display: flex;
    justify-content: space-around;
    top: 50%;
    align-items: start;
    padding: 15% 0% 0% 0%;
  }

  .imgIntegration1 > img {
    width: 134vw;
    position: relative;
    top: 81%;
    display: inline;
    left: 233%;
  }

  .imgIntegration2 > img {
    width: 17vw;
  }
  .imgIntegration2 > img {
    width: 37vw;
  }
  .contentmg1 {
    padding: 11% 0% 0% 3% !important;
  }
  .contentmg1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115vw;
    padding: 4% 0% 0% 3%;
  }
  #text_div {
    display: block;
  }
  #id_box {
    display: none;
  }
  .sectionplmthree {
    width: 99vw;
    position: relative;
    font-size: 2.3vw;
    top: 86% !important;
  }
  .imgIntegration1 > img {
    width: 136vw;
    height: 2.3vw;
  }
  .imgIntegration1 {
    display: block;
    justify-content: center;
    gap: 4vw;
    width: 5vw;
  }
  /*.contentdiv {
  width: 51%;
  height: 130vw !important;
  text-align: center;
  position: relative;
  top: -18vw;
}*/
  .contentdiv {
    width: 51%;
    height: 100vh !important;
    text-align: center;
    position: relative;
    top: -23rem;
  }
  /*.Product_Group {
    display: block;
    position: relative;
    left: 27%;
    top: 13% !important;
    padding: 13%;
    height: 100vh;
  }*/

.Product_Group {
  display: block;
  position: relative;
  left: 38%;
  padding: 25% 0% 0% 0%;
  height: 100vh;
}
  .contentdiv {
    width: 100%;
    height: 40vh !important;
    text-align: center;
  }

  .contentimgOTHERS {
    width: 100vw;
  }
}
