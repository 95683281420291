.Home_main {
    width: 100vw;
    height: auto;
    position: relative;
    overflow: hidden;
}


.home_inner {
    width: 100vw;
    height: 100vh;
    background-image: url("../image/home_bg.svg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
}

.shapeBox {
    width: 400px;
    height: 400px;
    position: relative;
    top: -5%;
    transform: rotate(128deg);
    filter: blur(77px);
}


.shapeBox_right {
    width: 500px;
    height: 500px;
    top: 40% !important;
    right: 0;
    filter: blur(77px);
    transform: rotate(108deg);
}

.shapeBox_inner {
    width: 100%;
    height: 100%;
    background: rgba(56, 127, 241, 0.4);
    border-radius: 40% 60% 21% 79% / 75% 35% 65% 25%;
}

.shapeBox_inner_right {
    background: rgba(207, 97, 203, 0.4) !important;
}

.home_section {
    position: relative;
    top: 24%;
    right: 0%;
    left: 2%;
}

.home_section h1 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 4vw;
    line-height: 150%;
    text-align: center;
    color: #2E6FD0;
    text-shadow: 1.5px 0px #2E6FD0;
    /* font-size: 60px;
       font-weight: 800;
       line-height: 90px;
           letter-spacing: 0.0253em;
       letter-spacing: 0em;
       text-align: center; 
    */

}

.boxShap {
    width: 300px;
    height: 300px;
    position: absolute;
    z-index: -1;
    bottom: -180%;
    transform: rotate(220deg);
    filter: blur(80px);
    left: -5%;
}

.boxShapInner {
    width: 100%;
    height: 100%;
    background: rgba(56, 127, 241, 0.4);
}

.home_section p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 170%;
    text-align: center;
    color: #3F4E66;
}

.boxshap {
    width: 350px;
    height: 250px;
    position: absolute;
    left: 0;
    bottom: -50%;
    border: 2px solid red;
    transform: rotate(-46.03deg);
    background: rgba(56, 127, 241, 0.4);
    filter: blur(77px);
}

.login-btn_home {
    width: 100%;
    margin-top: 7rem !important;
    margin-left: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginBtn {
    width: 200px;
    height: 30px;
    padding: 0.1rem !important;
    box-sizing: border-box;
    font-family: 'Lato' !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #3865AD !important;
}

.Getdemo {
    width: 200px;
    height: 47px;
    font-family: 'Lato' !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #3865AD !important;
}

.getdemoSpan {
    width: auto;
    margin-top: 2.5% !important;
}

/* .Getdemo p {
    padding-top: 3% !important;
    border: 2px solid red;
} */

.Getdemo:hover {
    color: white !important;
    cursor: pointer;
}

.Getdemo:hover {
    color: white;
    background: linear-gradient(90deg, #387FF1 10.16%, #CF61CB 128.04%);
}

.section_what {
    width: 100vw;
    height: auto;
    display: flex;
    margin-top: 10%;
    justify-content: space-around;
    padding: 0px 5px;
}

.section_what .section_frist {
    width: 50%;
    overflow: hidden;
    position: relative;
}

#beforeReload {
    width: 100%;
    position: absolute;
    top: 10%;
}

#afterReload {
    display: block;
    position: absolute;
    top: 10%;
}

.StyleFont {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 0;
}

.StyleFont span {
    width: 100%;
    font-family: 'Lato';
    text-shadow: 5px 0px #E1E1E1;
    font-size: 170px;
    /* font-size: 11.067708333333334vw; */
    font-weight: 800;
    line-height: 180px;
    text-align: center;
    color: #E1E1E1;
    position: absolute;
    top: 35%;
    left: 0;
}

.section_what .section_frist p {
    font-family: 'Lato';
    text-shadow: 3px 0px #5C90C4;
    font-size: 180px;
    /* font-size: 11.71875vw; */
    font-weight: 900;
    line-height: 195px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #5C90C4;
}

.dottPoint {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 18%;
    right: 16%;
    display: inline-block;
    background-color: #5C90C4;
    border-radius: 50%;
    z-index: 1;
}

.rectangleImg {
    width: 70px;
    position: absolute;
    top: 20%;
    right: 15%;
    transform: rotate(180deg);
}

.rectangle {
    width: 100%;
    height: 350px;
    background: white;
    transform: rotate(15deg);
    transition: 0.9s ease;
}


.section_frist:hover .rectangle {
    width: 0px;
    background: transparent;
}



.section_what .section_second {
    width: 50%;
    position: relative;
}

.section_secondInner {
    width: 100%;
    height: 100%;
}

.section_what .section_second P {
    font-family: 'Lato';
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    padding: 5% 14%;
    text-align: center;
    color: #3F4E66;
    position: relative;
    top: 5%
}

.whitebox {
    width: 54px;
    height: 273px;
    background: white;
    transform: rotate(12deg);
    position: absolute;
    top: 10%;
    right: 22%;
}

.section_shapBox {
    width: 50%;
    height: 50%;
    float: right;
}

.section_shapBox .shapBox_inner {
    width: 100%;
    height: 100%;
    background: rgba(56, 127, 241, 0.4);
    filter: blur(77px);
    border-radius: 40% 60% 21% 79% / 75% 35% 65% 25%;
    transform: rotate(207deg);
}


.About_Section {
    width: 100%;
    /* height: 200px; */
    margin-top: 0;
}

.col_homeAbout h2 {
    font-family: 'Lato';
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C5;
    padding-bottom: 1rem;
}

.col_homeAbout h2 span {
    font-weight: 800;
    text-shadow: 2px 0px #5C90C5;
}

.col_homeAbout p {
    font-family: 'Lato';
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;
}

.col_homeAbout .section_shapBox {
    width: 50%;
    height: 70%;
    position: relative;
    right: -20%;
    bottom: 40%;
}

.col_homeAbout .section_shapBox .shapBox_inner {
    background: rgba(207, 97, 203, 0.4) !important;
    filter: blur(70px) !important;

}

.ActionPLM_Home {
    margin-top: 20%;
    width: 100%;
    height: 700px;
    position: relative;
    box-sizing: border-box;
}

.CricleImg_industry {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    /* background: #F5F5F5;*/
    position: absolute;
    top: 0;
    left: 42.5%;
    z-index: 1;
}

.CricleImg_industry_inner {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: linear-gradient(228.9deg, #FFFFFF 12.77%, #FAF9FA 38.21%, #EFEEEF 106.59%);
    box-shadow: 1px 11px 11px 1px black;
}

.CricleImg_industry_inner img {
    margin-top: 0.5rem;
}


.Salesforce_Home {
    margin-top: 40%;
    width: 100%;
}

.Salesforce_Home .subbulid_text {
    margin-bottom: 0 !important;
}

.Salesforce_imgs {
    margin-top: 10%;

}

.flexSqureIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.Salesforce_Home .Salesforce_imgs_content {
    padding-top: 6%;
    position: relative;
}

.Salesforce_Home .Salesforce_imgs_content {
    font-family: Lato;
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;
}

.Salesforce_Home .Salesforce_imgs_content .row {
    padding-top: 4%;
    margin: 0 5rem;
}

.Salesforce_Home .Salesforce_imgs_content .row .col span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 0.5rem;
    border-radius: 3px;
    transform: rotate(45deg);
    background-color: #387FF1;
}

.Salesforce_Home .Salesforce_imgs_content .section_shapBox {
    width: 300px;
    height: 200px;
    position: absolute;
    top: 20%;
    right: -15%;
    background: rgba(207, 97, 203, 0.4) !important;
    transform: matrix(0.79, -0.54, 0.66, 0.81, 0, 0);
    filter: blur(70px);
    transform: rotate(108deg);
}

.sliderHome {
    position: relative;
    z-index: -1;
}

.sliderHome .CricleImg_industry {
    height: 350px;
    width: 350px;
    border-radius: 50%;
    /*box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
    background: white; */
    position: absolute;
    top: -15%;
    left: 35%;
}

/*.sliderHome .CricleImg_industry .CricleImg_industry_inner {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background: linear-gradient(228.9deg, #FFFFFF 12.77%, #FAF9FA 38.21%, #EFEEEF 106.59%);
    opacity: 0.17;
    position: relative;
    box-shadow: -140px 90px 40px 0px rgba(47, 15, 15, 0.579);
}*/

.AboutSection {
    margin-top: 20%;
}

.sliderCards p {
    font-family: 'Lato';
    font-size: 40px;
    font-weight: 600;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C5;
}

.sliderHome_Section {
    margin-top: 40%;
    position: relative;
    left: 10%;
}

.sliderCards {
    position: relative;
    left: 5%;
}

.sliderCards .DiscreteIndustry {
    position: absolute;
    left: -3%;
    top: 36%;
}

.sliderCards .ProcessIndustry {
    position: absolute;
    right: 32%;
}

.sliderHome .CricleImg_industry p {
    font-family: 'Lato';
    padding: 1rem;
    padding-left: 1.8rem;
    font-size: 44px;
    font-weight: 800;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C4;
    position: absolute;
    top: 25%;
    left: 4%;
}

.sliderItem {
    width: 250px;
    height: auto;
    border-radius: 50%;
}

.sliderItem div p {
    font-family: 'Lato';
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;
}

.border-gradient {
    border: 4px solid;
    border-image-slice: 1;
}

.border-gradient-purple {
    border-image-source: linear-gradient(180deg, #387FF1 0%, #CF61CB 100%);
}

.IndustryMain {
    font-family: 'Lato';
    letter-spacing: 0em;
    text-align: center;
    margin-top: 50%;
}

.Industries p {
    font-size: 48px;
    font-weight: 800;
    line-height: 72px;
}

.Industries .subbulid_text {
    font-family: 'Lato';
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    color: #3F4E66;
    padding-top: 1rem;
    padding-bottom: 10%;
}

.IndustryMain .discrete,
.IndustryMain .process {
    display: flex;
    justify-content: center;
    align-items: center;
}

.IndustryMain .discrete p,
.IndustryMain .process p {
    width: 40%;
    overflow: hidden;
    border-bottom: 4px solid #5C90C5;
    font-size: 35px;
    font-weight: 600;
    line-height: 53px;
    color: #5C90C5;

}

/* .contentflex1 {
    width: 100%;
    height: 100px;
    
    position: relative;
    top: 0;
} */
.contentflex {
    width: 108%;
    height: 100px;
    margin-top: 3rem;
    background-image: url("../image/linearGradient.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.09s linear;
    transform: scale(0.9);
}

/* .rotateImg {
    transform: rotate(-180deg);
}

.rotatetext {
    transform: rotate(180deg);
} */

.content_inner a {
    text-decoration: none;
}


.contentflex div {
    width: 90%;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;
    padding-top: 1.7rem;
}

.contentflex:hover {
    width: 110%;
    cursor: pointer;
    transition: width all 0.09s linear;
    transform: scale(1);
}

.textShadow:hover {
    box-shadow: -6px 7px 8px rgba(86, 86, 86, 0.25);

}

.rotatetext:hover {
    box-shadow: -6px 7px 8px rgba(86, 86, 86, 0.25);
}

.IndManu:hover {
    width: 120%;
    cursor: pointer;
    transition: 0.01s linear;
}

.contentflex .toppadding:hover {
    font-size: 25px;
    font-weight: 700;
}




.progressBar {
    width: 100%;
    margin-top: 35%;
    position: relative;
    top: 70%;
    border: 2px solid red;
}

.progress {
    background-color: transparent !important;
}

.progressBar .progressItem {
    height: auto;
    position: absolute;
    top: 16%;
    left: 35%;
}

#progressBar {
    width: 75%;
    height: 100vh;
}


#first {
    position: relative;
    top: 10%;
    transform: rotate(90deg);
}

#Second {
    margin-top: 115%;
    transform: rotate(90deg);
}

#Third {
    margin-top: 115%;
    transform: rotate(90deg);
}

.progressItem {
    width: 7.9rem;
    margin-top: 0.4%;
}

.bg-changeColor {
    background: linear-gradient(180deg, #387FF1 0%, #CF61CB 100%);
}

/* Industries */

.progressBar_Display {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width:768px) and (max-width:1200px) {
    .About_Section .AboutImg {
        margin-top: 20%;
    }

    .Salesforce_Home {
        margin-top: 35%;
    }

}

@media (min-width:991px) and (max-width:1024px) {

    .navbar {
        font-size: 17px !important;
        padding-left: 0% !important;
    }

    /* .section_what {
        margin-top: 20% !important;
    } */

    .sliderCards {
        left: 6.5% !important;
    }

    .sliderCards .DiscreteIndustry {
        position: absolute;
        left: -19% !important;
        top: 37%;
    }

    .sliderCards .ProcessIndustry {
        right: 26%
    }

    #progressBar {
        width: 85% !important;
        height: 180vh !important;
    }

    #progressLine {
        margin-top: 54% !important;
        height: 380px !important;
    }

    .contentflex .toppadding:hover {
        font-size: 19px !important;
        font-weight: 700 !important;
    }

    .contentflex {
        height: 70px;
    }

    .contentflex div {
        font-size: 15px;
        padding-top: 0.7rem;
    }

    .IndustryMain {
        margin-top: 65%;
    }

    .IndustryMain .discrete p,
    .IndustryMain .process p {
        width: 60%
    }

    .rectangleImg,
    .dottPoint {
        right: 0% !important;
    }

    .StyleFont span {
        top: 28%;
    }

    .contentBoxInner {
        line-height: 32px !important;
        font-size: 20px !important;
    }

    .imgStyle .imgCricle .innerStyle {
        width: 150px !important;
        height: 150px !important;
        left: -10% !important;
    }


    .Footer {
        flex-wrap: wrap;
        justify-content: space-around !important;
        align-items: inherit !important;
    }

    .Footer .FristSection,
    .SecondSection,
    .ThridSection {
        width: auto !important;
    }

    .SecondSection .Service-Section {
        padding: 9% 0 0 0 !important;
    }

    .ThridSection {
        padding-top: 2%;
    }

}

@media (min-width:1024px) and (max-width:1440px) {
    #progressBar {
        width: 85% !important;
        height: 180vh !important;
    }

}

/* @media (min-width:991px) and (max-width:1125px) {

    
} */

@media (min-width:2000px) {

    .StyleFont span {
        top: 41%;
    }

    .rectangleImg,
    .dottPoint {
        right: 30%;
    }
}