.sectionplmone {
  width: 100%;
  height: 45vw;
  display: flex;
  align-items: center;
  justify-content: start;
}

.image-sectionplmone {
  width: 90%;
  position: absolute;
}

.image-sectionplmone > img {
  width: 100vw;
  position: relative;
}
.content-sectionplmone {
  width: 60%;
  padding: 10% 10%;

  font-size: 1.5vw;

  justify-content: center;
}

.content-sectionplmone > h1 {
  font-size: 3.3vw;
  font-weight: 390;
  margin-bottom: 5%;
  /*color: #5c90c4;*/
  color:#8288C2 ;
  text-align: center;
}
.content-sectionplmone > p {
  color: #3f4e66;
  width: 88%;

  text-align: center;
}
.boldSpanone {
  font-weight: 700;
}
.title2 {
  position: relative !important;
  left: -4% !important;
}
.title4 {
  position: relative !important;
  left: -5% !important;
}
#Integration_Management1{
  display:none;
  


  }
  .title1 {
    position: relative !important;

    left: -3.8% !important;
  }

  .title3 {
    position: relative !important;
    left: -4% !important;
}


@media (min-width: 319px) and (max-width: 427px){
  #Integration_Management{
    display: none;
  }
 
  /*#Integration_Management1{
    
    z-index: -4 !important;
    width: 100vw;
    display: block;
    height: 121vh;
  position: relative;
  top: -7% !important;
  
  }*/
  #Integration_Management1 {
    z-index: -4 !important;
    width: 100vw;
    display: block;
    height: 199vw;
    position: absolute;
    top: 78% !important;
}
  .content-sectionplmone > h1 {
    font-size: 300%;
    position: absolute;
    width: 100% !important;
    font-weight: 390 !important;
    margin-bottom: 5%;
    left:-4vw !important ;
  }
  /*.content-sectionplmone {
    width: 95%;
    padding: 10% 10%;
    font-size: 77%;
    justify-content: center;
    position: absolute;
    top: 4%;
}*/
.content-sectionplmone {
  width: 109vw;
  padding: 10% 10%;
  font-size: 4vw;
  justify-content: center;
  position: absolute;
  top: 4%;
}
/*.sectionplmone {
  
  height: 67vw;
  
}*/

.sectionplmone {
  width: 100%;
  height: 400vw;
  display: block;
  align-items: center;
  justify-content: start;
}

/*.image-sectionplmone {
    width: 90%;
    position: absolute;
    top: -2%;
}*/
.image-sectionplmone {
  width: 109vw;
  position: absolute;
  top: 3%;
}

.classnameicon_img {
  position: absolute;
  left: -115%;
  top: 1%;
}
.parafirst {
  position: absolute;
  left: -184%;
  top: 2% !important;
}
.para2 {
  position: absolute !important;
  left: -54% !important;
  top: 1% !important;
}
.img_classnameicon {
  position: absolute;
  top: 3%;
  left: 1rem;
}
.para1 {
  left: 96% !important;
  position: absolute !important;
  top: 26%;
}
}