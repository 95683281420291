.sectionplmtwo {
  width: 100%;
  height: 45vw;

  display: flex;
  align-items: center;
  justify-content: center;
}
.image-sectionplmtwo {
  width: 40%;
  position: relative;
  top: 12%;
}
.image-sectionplmtwo > img {
  width: 109%;
  position: relative;
  right: 35%;
}
.content-sectionplmtwo {
  width: 62%;
  padding: 10% 10%;
  display: flex;
  font-size: 1.5vw;
  position: relative;
  top: 12%;
}
.content-sectionplmtwo > h1 {
  font-size: 3.3vw;
  font-weight: 390;
  margin-bottom: 5%;
  color: #5c90c4;
  text-align: center;
}
.boldSpan {
  font-weight: 700;
}
.imgIntegration {
  display: flex;
  justify-content: center;
  gap: 4vw;
  width: 25vw;
}
.imgIntegration1 {
  display: flex;
  justify-content: center;
  gap: 4vw;
  width: 5vw;
}
.imgIntegration1 > img {
  width: 1vw;
  height: 1vw;
}
.imgIntegration2 {
  width: 20vw;
  height: 10vw;
}
.imgIntegration > img {
  width: 3vw;
  height: 7vw;
}

.imgIntegration > p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  padding: 2.5% 0% 0% 0%;
  width: 17vw;
  color: #3f4e66;
  font-weight: 599;
}
.contentimgs {
  width: 100%;
}
#imgIntegrations {
  position: relative;
  left: 0%;
}
.contentmg1 {
  width: 100vw;
}
.icon_PBA{
  position: relative;
  top: 2%;
}

.imgIntegration2 > img {
  width: 15vw;
}
.contentimgOTHERS {
  width: 100vw;
}
.icon1 {
  position: relative !important;
  left: 1% !important;
  text-align: start;
}
.icon2 {
  position: relative !important;
  left: 0% !important;
}

.parasqure {
  position: relative;
  left: -2%;
  width: 7%;
  /*align-items: center;*/
  text-align: start;
}
.para1 {
  left: -6% !important;
  position: relative !important;
}
.parafirst {
  position: relative;
  left: -14%;
  top: 3% !important;
}
/*.title3 {
  position: relative !important;
  left: 3% !important;
}*/
.Usertitle {
  position: relative !important;
  left: -9% !important;
}
.classtitle {
  position: relative !important;
  left: -14% !important;
}
.classtitles {
  position: relative !important;
  left: -1% !important;
  text-align: start;
}
.para2 {
  position: relative !important;
  left: -12% !important;
  top: 6% !important;
}
.imgtext {
  position: relative;
  left: -14%;
  text-align: center;
}
.Workflow_text {
  text-align: start;
}
.soureimg_text {
  position: relative;
  left: -7%;
}
.Easyimg_text {
  text-align: start;
  width: 10vw;
}
.classname_para {
  position: relative;
  left: -13%;
}
#section-text {
  display: none;
}
@media screen and (min-width: 319px) and (max-width: 348px) {
  #content_divs{
    display: inline;
    top: 57% !important;
    position: relative;
  }
  #content_div{
    display: inline;
    top: 47% !important;
    position: relative;
  }
  .sectionplmtwo {
    height: 100% !important;
    position: absolute;
    top: -15%;
  }
  #image_box{
    width: 86vw;
    position: relative;
    top: -2% !important;
  }
  #image_boxs{
    height: 100% !important;
    position: absolute;
    top: -54% !important;
  }
 /* .content-sectionplmtwo {
    display: inline;
    top: 25% !important;
    position: relative;
    padding-top: 189%;
  }*/
  .content-sectionplmtwo {
    display: inline;
    top: 43.9% !important;
    position: relative;
    padding-top: 189%;
}
  /*.image-sectionplmtwo {
    width: 86vw;
    position: relative;
    top: 1% !important;
  }*/ .image-sectionplmtwo {
    width: 86vw;
    position: relative;
    top: -1% !important;
}
  /*.icon2 {
    position: absolute !important;
    left: -326% !important;
    top: 15% !important;
  }*/
  .icon2 {
    position: absolute !important;
    left: -280% !important;
    top: 16% !important;
  }

    .icon1 {
      position: relative !important;
      left: -59% !important;
      text-align: start;
  }
  .parafirst {
    position: absolute;
    left: -200%;
    top: 21% !important;
}
.icon_PBA{
  position: absolute !important;
  left: -264% !important;
  top:17%
}
#content_text{
  display: inline;
  top: 44% !important;
  position: relative;
}
#image_div{
  width: 86vw;
  position: relative;
  top: -2% !important;
}
}

/*@media screen and (min-width: 348px) and (max-width: 375px){
  .sectionplmtwo {
    height: 100% !important;
    position: absolute;
    top: -15%;
}
}*/


@media screen and (min-width: 348px) and (max-width: 375px) {
  #content_text{
    display: inline;
    top: 45.5% !important;
    position: relative;
  }
  #image_div{
    width: 86vw;
    position: relative;
    top: -2% !important;
  }
  #content_div{
    display: inline;
    top: 53% !important;
    position: relative;
  }
  .sectionplmtwo {
    height: 100% !important;
    position: absolute;
    top: -16%;
}
  .image-sectionplmtwo {
    width: 86vw;
    position: relative;
    top: -1% !important;
  }

.icon2 {
  position: absolute !important;
  left: -261% !important;
  top: 14% !important;
}
.icon1 {
  position: relative !important;
  left: -60% !important;
  text-align: start;
}
.icon_PBA {
  position: absolute !important;
  left: -232% !important;
  top: 17%;
}
.content-sectionplmtwo {
  display: inline;
  top: 46.5% !important;
  position: relative;
}
#image_box {
  width: 86vw;
  position: relative;
  top: -3% !important;

}
#content_divs{
  display: inline;
  top: 61% !important;
  position: relative;
}
#image_boxs{
  height: 100% !important;
  position: absolute;
  top: -171% !important;
}
}


@media screen and (min-width: 375px) and (max-width: 425px) {
  #content_text{
    display: inline;
    top: 43.9% !important;
    position: relative;
  }
  #image_div{
    width: 86vw;
    position: relative;
    top: -2% !important;
  }
  #content_div{
    display: inline;
    top: 53% !important;
    position: relative;
  }
  #content_divs{
    display: inline;
    top: 52% !important;
    position: relative;
  }
  .sectionplmtwo {
    height: 100% !important;
    position: absolute;
    top: -11%;
}
.image-sectionplmtwo {
  width: 86vw;
  position: relative;
  top: -2% !important;
}
.content-sectionplmtwo {
  display: inline;
  top: 47% !important;
  position: relative;
}
.icon2 {
  position: absolute !important;
  left: -243% !important;
  top: 14%;
}
.parafirst {
  position: absolute;
  left: -180%;
  top: 12.9% !important;
}
.icon1 {
  position: relative !important;
  left: -60% !important;
  text-align: start;
}
.icon_PBA{
  position: absolute !important;
  left: -217% !important;
  top:17%
}
#image_box {
  width: 86vw;
  position: relative;
  top: -3% !important;

}
}
@media  (min-width: 375px) and (max-width: 380px) {
  .content-sectionplmtwo {
    display: inline;
    top: 43% !important;
    position: relative;
}
}
@media screen and (max-width: 426px) {
  .imgIntegration > img {
    width: 64vw;
    height: 10vw;
  }
 
  .imgIntegration > p {
    font-size: 3.5vw;
    width: 37vw;
  }
  .image-sectionplmtwo > img {
    width: 90vw;
    position: relative;
    left: 1%;
  }

  .imgIntegration {
    display: block;
    margin: 15% !important;
  }
  image-sectionplmone > img {
    display: none;
  }

  .classnameicon_img {
    position: absolute;
    left: -47%;
    top: 12%;
  }

  .parafirst {
    position: absolute;
    left: -140%;
    top: 171% !important;
  }

  .imgIntegration > img {
    width: 10vw;
    height: 15vw;
  }
  .imgIntegration > p {
    font-size: 4vw;

    width: 39vw;
  }
  .imgIntegration {
    display: flex;
    justify-content: center;
    gap: 4vw;
    width: 88vw;
  }

  .para2 {
    position: absolute;
    left: -30% !important;
    top: 27% !important;
  }

  .img_classnameicon {
    position: absolute;
    top: 73%;
    left: 5rem;
  }

  #section-text {
    display: block;
  }
  #secation-Part {
    display: none;
  }
  .para1 {
    left: 185% !important;
    position: absolute !important;
    top: 152%;
  }
  .sectionplmtwo {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgIntegration > img {
    width: 10vw;
    height: 15vw;
  }

  .imgtext {
    position: relative;
    left: -66%;
    text-align: center;
  }
}

@media screen and (min-width: 319px) and (max-width: 426px) {
  /*.icon_PBA{
    position: absolute !important;
    left: -264% !important;
    top:2%
  }*/
  #section-text {
    display: block;
  }
  #secation-Part {
    display: none;
  }
 /* .parafirst {
    position: absolute;
    left: -237%;
    top: 21% !important;
  }/*
  /*.icon1 {
    position: absolute !important;
    left: -84% !important;
    text-align: start;
  }*/
  /*.icon2 {
    position: absolute !important;
    left: -323% !important;
    top: 17% !important;
  }*/

  .parafirst {
    position: absolute;
    left: -173%;
    top: 18.9% !important;
  }
  .para2 {
    position: absolute !important;
    left: -42% !important;
    top: 15% !important;
  }
  .classnameicon_img {
    position: absolute;
    left: -132%;
    top: 15%;
  }
  .img_classnameicon {
    position: absolute;
    top: 16%;
    left: 0rem;
  }
  .para1 {
    left: 125% !important;
    position: absolute !important;
    top: 22%;
  }

  .image-sectionplmtwo > img {
    width: 109%;
    position: relative;
    right: 33%;
    top: 4%;
    height: 100% !important;
  }
  .image-sectionplmtwo > img {
    width: 109%;
    position: relative;
    right: -1%;
    top: 62%;
    height: 100% !important;
  /*  padding-top: 131%;
    padding-bottom: 8%;*/
  }
  .imgIntegration > img {
    width: 19vw;
    height: 11vw;
  }
  .img_Traceability {
    position: relative;
    left: -33%;
  }
  .classname_para {
    position: relative;
    left: -44%;
  }
  .img_flow {
    position: relative;
    left: -15%;
  }
  .Usertitle {
    position: relative !important;
    left: -23% !important;
    top:2%;
  }
  .icon3 {
    position: absolute !important;
    left: -237% !important;
    top:12%;
  }
  .classtitle {
    position: absolute !important;
    left: -162% !important;
    top: 17%;
  }

  .parasqure {
    position: relative;
    left: -12%;
    top: 11%;
  }
  .classtitles {
    position: relative;
    left: -29% !important;
    top:-2%;
  }

  .icon_IMG {
    position: absolute;
    left: -125%;
    top: 12%;
  }
  .icon_text {
    position: absolute;
    left: -1%;
    top: 14%;
  }

  .Easyimg_text {
    position: absolute;
    left: -113%;
    top: 16%;
  }
  .classname_icon {
    position: absolute;
    left: -58%;
    top:22%;
  }
  .soureimg_text {
    position: absolute;
    left: 49%;
    top:28%;
  }
  .Workflow_text {
    position: absolute;
    left: 201%;
    top: 14%;
  }
  .icon_img {
    position: absolute;
    top: 29%;
    left: 63%;
  }
  .imgtext {
    position: relative;
    left: -73%;
    top: -1.5vw;
    text-align: center;
  }
 
}
@media screen and (min-width: 319px) and (max-width: 375px) {
 
  .para2 {
    position: absolute !important;
    left: -42% !important;
    top: 17% !important;
  }
  .classnameicon_img {
    position: absolute;
    left: -136%;
    top: 16%;
  }
  .img_classnameicon {
    position: absolute;
    top: 17%;
    left: 0rem;
  }
  .para1 {
    left: 123% !important;
    position: absolute !important;
    top: 22%;
  }
  .image-sectionplmtwo > img {
    width: 109%;
    position: relative;
    right: -1%;
    top: 62%;
    height: 100% !important;
   /* padding-top: 131%;
    padding-bottom: 27%;*/
  }
  #section-text {
    display: block;
  }
  #secation-Part {
    display: none;
  }

  #search_div {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    height: 100vh;
  }
}
