.ActionPLM {
    margin-top: 10%;
    position: relative;
}

.Plm_Inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.para_bulid {
    font-family: Lato;
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C5;
}

.para_bulid span {
    font-weight: 800;
    text-shadow: 1px 0px #5C90C5;
    letter-spacing: 0.056em;
}

.Salesforce_Home .para_bulid span{
    text-shadow: 0px 0px #5C90C5;
}


.ActionPLM_Home .subbulid_text,
.Salesforce_Home .subbulid_text {
    font-family: Lato;
    font-size: 25px;
    font-weight: 400;
    padding: 0 2rem;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;
}

.leftIndustry {
    width: 50%;
}

.RightIndustry {
    width: 50%;
}

.leftIndustry img {
    width: 100%;
}

.RightIndustry img {
    width: 100%;
}

#DiscreteIndustry {
    font-family: 'Lato', sans-serif;
    font-size: 35px;
    font-weight: 600;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C5;
    border-bottom: 4px solid #8AB3DC;
    margin-right: 20%;
    padding-bottom: 1.5rem;
}

#ProcessIndustry {
    font-family: 'Lato', sans-serif;
    font-size: 35px;
    font-weight: 600;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C5;
    border-bottom: 4px solid #F4E0F4;
    margin-left: 20%;
    padding-bottom: 1.5rem;

}

.imgStyle {
    width: 100%;
    height: 150px;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2.2vw;
    left: 0.1vw;
}

.imgStyle .imgCricle {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    background: linear-gradient(228.9deg, #FFFFFF 12.77%, #FAF9FA 38.21%, #EFEEEF 106.59%);
    position: relative;
    box-shadow: -10px 10px 10px 5px rgba(0, 0, 0, 0.27);
}

.imgStyle .imgCricle .innerStyle {
    width: 230px;
    height: 230px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: #F8F8F8;
    /* background-image: url("../../image/DDIcon/cricle.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; */

}

.imgStyle .imgCricle .innerStyle img {
    width: 80%;
    height: auto;
    margin-top: 12%;
}

.ActionPLM_inner {
    position: relative;
}

.ActionPLM_Home_content {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    column-gap: 11vw;
    position: absolute;
    top: 40%;
    z-index: 1;
    padding-top: 2.5rem;
}

.ActionPLM_Home_content .content_inner {
    width: 50%;
    padding-top: 1%;
    margin-right: 10%;
}

.contentRight {
    margin-right: 0 !important;
}

.ActionPLM_Home_content .list-group-item,
.IndustryMain .list-group-item {
    border: none;
    background-color: transparent !important;
    padding-left: 10px !important;
}

.ActionPLM_Home_content .list-group-item div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

}

.ActionPLM_Home_content .list-group-item div div {
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    text-align: left;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
}

.list-group-item div img {
    width: 5%;
    height: 5%;
    margin-top: 0.7rem;
    margin-right: 0.5rem;
}

.ActionPLM_Home_content .content_inner .section_shapBox {
    width: 100%;
    height: 200px;
    position: absolute;
    right: 70%;
    background: rgba(56, 127, 241, 0.4) !important;
    filter: blur(80px) !important;
    transform: rotate(120deg);
}



@media (min-width:992px) and (max-width:1200px) {
    .ActionPLM_Home_content .list-group-item div div {
        font-size: 17px;
        line-height: 38px;
    }

}

@media (min-width:768px) and (max-width:1200px) {
    .ActionPLM_Home_content .list-group-item div div {
        font-size: 17px;
        line-height: 30px;
    }

    .imgStyle .imgCricle {
        width: 120px;
        height: 120px;

    }

    .ActionPLM_Home_content .content_inner {
        padding-top: 4%;
        padding-left: 0;
        margin-right: 15%;
    }

    .contentRight {
        padding-left: 3% !important;
    }
}