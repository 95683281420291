.section_benefit {
    width: 100vw;
    height: 100%;
    margin-top: 20%;
}

.section_benefit .key_benefit {
    width: 100%;
    height: 100%;
    margin-bottom: 5%;
    overflow: hidden;
}

.section_benefit .key_benefit h2 {
    font-family: 'Lato';
    font-size: 48px;
    font-weight: 800;
    font-style: normal;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    color: #5C90C5;
    text-shadow: 2px 0px #5C90C5;
}

.keybnf {
    font-weight: 500;
    text-shadow: 0px 0px #5C90C5;

}

.section_benefit .key_benefit p {
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;

}

.percentage_ {
    position: relative;
    border: 2px solid transparent;
}

.mt-5 {
    margin-top: 50% !important;
}

.cricleMain {
    width: 160px;
    height: 160px;
    position: absolute;
    top: 0;
    left: 23%;
}

.cricleMain_even:nth-child(2n) {
    width: 160px;
    height: 160px;
    position: absolute;
    bottom: 0;
    left: 12%;
}

.cricleMain svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, -11%) rotate(-90deg);
}


/* .percentage_ .percentage_imgbox img  {
    position: absolute;
    top: 60%;
}*/

.percentage_ .percentage_imgbox {
    margin-top: 25%;
}

.percentage_ .percentage_imgbox_reverse {
    margin-bottom: 25%;
}

.percentage_ .percentage_imgbox_reverse img {
    transform: rotate(180deg);
}

.contentBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2%;
}

.contentBoxInner {
    width: 80%;
    height: 120px;
    font-family: Lato;
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;

}

.contentBoxInner p {
    margin-top: 1rem;
}

.smallContentBoxInner {
    width: 50% !important;
    margin-left: 26%;
}

/*
.RCF {
    margin-top: 0rem !important;
    margin: 0;
}
.PFM {
    padding: 0.9rem !important;
    margin: 0;
} */

.marigntopOdd {
    margin-top: 85%;
}

.border-gradient_100per {
    border-width: 2px;
    border-radius: 0px 15px 0px 15px;
    border: double 2px transparent;
    background-image: linear-gradient(white, white),
        linear-gradient(180deg, #358BEF 0%, #55C8F2 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.border-gradient_50per {
    border-width: 2px;
    border-radius: 0px 15px 0px 15px;
    border: double 2px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-image: linear-gradient(white, white),
        linear-gradient(180deg, #A21E6C 0%, #F551C1 100%);
}

.border-gradient_35per {
    border-width: 2px;
    border-radius: 0px 15px 0px 15px;
    border: double 2px transparent !important;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid;
    background-image: linear-gradient(white, white),
        linear-gradient(180deg, #D34A1A 0%, #F1A12E 100%);
}

.border-gradient_25per {
    border-width: 2px;
    border-radius: 0px 15px 0px 15px;
    border: double 2px transparent !important;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid;
    background-image: linear-gradient(white, white),
        linear-gradient(180deg, #482F9F 0%, #7F21AD 100%);
}

/* #progressLine svg path {
    stroke-dasharray: 300;
    stroke-dashoffset: 100;

} */

svg circle {
    fill: none;
    stroke-width: 20px;
    stroke-dasharray: 472;
    stroke-dashoffset: 472;
}

.Main_animat svg circle {
    stroke: url(#paint0_linear_219_9377);
    animation: beforepercentage 2s linear forwards;
}

@keyframes beforepercentage {
    100% {
        stroke-dashoffset: 472;
    }
}


.Main_animation svg circle {
    stroke: url(#paint0_linear_219_9377);
    animation: percentage 2s linear forwards;
}

@keyframes percentage {
    100% {
        stroke-dashoffset: 0;
    }
}

.Main_animation .criclegradient50 svg circle {
    stroke: url(#paint0_linear_219_9389);
    animation: fifty 2s linear forwards;
}

@keyframes fifty {
    100% {
        stroke-dashoffset: 260;
    }
}

.Main_animation .criclegradient35 svg circle {
    stroke: url(#paint0_linear_219_9397);
    animation: threefive 2s linear forwards;
}

@keyframes threefive {
    100% {
        stroke-dashoffset: 306;
    }
}

.Main_animation .criclegradient25 svg circle {
    stroke: url(#paint0_linear_219_9405);
    animation: twofive 2s linear forwards;
}

@keyframes twofive {
    100% {
        stroke-dashoffset: 380;
    }
}

.percentage_cricle {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    padding: 20px;
    background: #F2F2F2;
    box-shadow: -4px -4px 6px 0px rgba(0, 0, 0, 0.15), 4px 4px 6px 0px rgba(0, 0, 0, 0.29);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

/* .percentage_cricle ::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 70%;
    box-shadow: inset -4px -4px 8px rgba(0, 0, 0, 0.15), inset 4px 4px 6px rgba(0, 0, 0, 0.29);
    border-radius: 50%;
    z-index: 1;

} */

.percentage_cricleInner {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    /* box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(0, 0, 0, 0.29); */


}

#digits {
    font-family: Lato;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0em;
    text-align: center;
    color: #3865AD;
}


@media (min-width: 768px) and (max-width:1023px) {
    .cricleMain {
        top: -9%;
        left: 13%;
    }

    .cricleMain_even:nth-child(2n) {
        bottom: -13%;
        left: 3%;

    }
}

@media (min-width: 1024px) and (max-width:1200px) {
    .cricleMain {
        top: -9%;
        left: 11%;
    }

    .cricleMain_even:nth-child(2n) {
        bottom: -13%;
        left: 5%;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    #RCF p {
        margin: 0 !important;
    }
}