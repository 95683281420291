/*.swiper-button-next, .swiper-button-prev{
    width: 3vw;
    position: absolute;
    top:52%;
    right: 5%;
    left: 5%;
}
.swiper-button-next{
    width: 3vw;
    position: absolute;
    top:52%;
    right: 5%;
    color:  #888888;
    font-size:20vw ;
    font-weight: 900;
}
.swiper-button-prev{
    width: 3vw;
    position: absolute;
    top:52%;
    left: 5%; 
    color:  #888888;
    font-size:20vw ;
    font-weight: 900;
}



.swiper-button-next:hover, .swiper-button-prev:hover{

border-radius: 100%;
width: 3%;
background-color: #3865AD;


}
.swiper-button-next:hover, .swiper-button-prev:hover{
    border: 1px solid;
    padding: 10px;
    box-shadow: 0px 3px #5B5B5B;

    
}
.swiper-button-next:after, .swiper-button-prev:after{
    font-size: 1.3vw;
    font-weight: 700;
    color: #5B5B5B;

  width: 13vw;

}
.swiper-button-next:before, .swiper-button-prev:before{
   width: 13vw;
   font-weight: 700;
 
}
*/
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
  color: #888888;
}

.swiper-button-prev:hover {
  background: #3865ad;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.swiper-button-next:hover {
  background: #3865ad;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.swiper-button-prev:hover::after {
  color: white;
}

.swiper-button-next:hover::after {
  color: white;
}

.swiper-button-prev {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 5rem !important;
}

.swiper-button-next {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5rem !important;
}
@media (min-width: 768px) and (max-width: 992px) {
  .swiper-button-next {
    margin-right: 2rem !important;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    top: 44% !important;
  }
  .swiper-button-next {
    margin-right: inherit !important;
  }
  .swiper-button-prev{
    margin-left: inherit !important;

  }
}
