.Req {
  width: 100%;
  height: 88vw;
  position: relative;
  padding: 0 0%;
  top: 0;
  left: 4%;
  bottom: 5%;
}

.Req_one {
  position: absolute;
  width: 40%;
  left: 23%;
  top: 15%;
  display: none;
}

.Req_two {
  position: absolute;
  top: 36.5%;
  left: 50%;
  width: 29vw;
  height: 35%;
  display: none;
}

.Req_three {
  position: absolute;
  top: 66%;
  left: 24%;
  width: 40vw;
  display: none;
}

.svgone {
  width: 70vw;
  height: 70vw;
  left: 9%;
  top: 13%;
  position: absolute;
}

#box {
  display: none;
}

/* Page2 */
.Page2_p1 {
  position: absolute;
  top: 21%;
  left: 10%;
  width: 44%;
}

.Page2_p2 {
  position: absolute;
  top: 36%;
  left: 56%;
  width: 32%;
  display: none;
}

.Page2_p3 {
  position: absolute;
  top: 59%;
  left: 29%;
  width: 42%;
  display: none;
}

.svgpath {
  width: 77vw;
  height: 100vw !important;
  left: 6%;
  top: -5%;
  position: absolute;
}

.svgthree {
  width: 48vw;
  height: 85vw !important;
  position: relative;
  left: 1%;
}

.Page3_p1 {
  position: absolute;
  top: 10%;
  left: 17%;
  width: 42%;
}

.Page3_p2 {
  position: absolute;
  top: 34%;
  left: 35%;
  width: 50%;
  display: none;
}

.Page3_p3 {
  position: absolute;
  top: 61%;
  left: 48%;
  width: 25%;
  display: none;
}

.svgfour {
  width: 60vw;
  height: 100vw;
  top: -4%;
  left: 0%;
  z-index: -1;
  position: relative;
}

.Page4_p1 {
  position: absolute;
  top: 12% !important;
  width: 55vw;
  left: 11%;
  z-index: 1;
}

.Page4_p2 {
  width: 58vw;
  position: absolute;
  top: 49%;
  left: 22%;
  display: none;
}

.Page4_p3 {
  position: absolute;
  top: 72%;
  left: 12%;
  width: 60vw;
  display: none;
}

.svgfive {
  height: 65vw;
  width: 77vw;
  position: relative;
  left: -4%;
  top: 13%;
  z-index: -1;
}

.Page5_p1 {
  position: absolute;
  top: 26%;
  left: 3%;
  width: 83vw;
}

.Vector_one1 {
  display: none;
}

.svgsix {
  width: 63vw;
  height: 100vw;
  position: relative;
  left: -4%;
  top: -5%;
}

.Page6_p1 {
  position: absolute;
  top: 15%;
  left: 16%;
  width: 67vw;
}

.Page6_p2 {
  position: absolute;
  top: 38%;
  left: 37%;
  width: 32vw;
  display: none;
}

.Page6_p3 {
  width: 60vw;
  position: absolute;
  top: 67.8%;
  left: 9.2%;
  display: none;
}

.svgseven {
  position: absolute;
  height: 50vw;
  width: 70vw;
  left: 10%;
  top: 28%;
}

.Page7_p1 {
  position: absolute;
  top: 22%;
  left: 17%;
  bottom: 20%;
  width: 60vw;
}

.Page7_p2 {
  position: absolute;
  top: 62%;
  left: 24%;
  width: 62vw;
  display: none;
}

.svgeight {
  height: 100vw;
  width: 80vw;
  position: absolute;
  left: 5%;
  top: -4%;
}

.Page8_p1 {
  position: absolute;
  top: 22%;
  left: 5%;
  width: 77vw;
}

.Page8_p2 {
  position: absolute;
  top: 57%;
  left: 19%;

  width: 64vw;
  display: none;
}

.svgnine {
  width: 76vw;
  height: 100vw;
  position: absolute;
  z-index: -1;
  left: 6%;
  top: -4%;
}

.Page9_p1 {
  position: absolute;
  top: 19%;
  left: 10.5%;
  width: 62vw;
}

.Page9_p2 {
  position: absolute;
  top: 62%;
  left: 18%;
  width: 67vw;

  display: none;
}

.dashed {
  stroke-dasharray: 10;
}

.path {
  stroke-dasharray: 3600;
  stroke-dashoffset: 3600;
  animation: dash 4s linear /*alternate*/ forwards /*infinite*/;
}

.Page10_p2 {
  position: absolute;
  top: 43%;
  left: 10%;
  width: 62vw;
  display: none;
}

.Page10_p3 {
  width: 60vw;
  position: absolute;
  top: 70.1%;
  left: 30%;
  display: none;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.svgtwelve {
  position: absolute;
  width: 70vw;
  left: 2%;
  top: 0;
  height: 100vw;
}

.dashed1 {
  stroke-dasharray: 10;
}

.path1 {
  stroke-dasharray: 3800;
  stroke-dashoffset: 3800;
  animation: dash1 4s linear alternate forwards;
}

.path10 {
  stroke-dasharray: 3800;
  stroke-dashoffset: 3800;
  animation: dash1 4s linear alternate forwards;
}

.svgTen {
  width: 63vw;
  height: 100vw;
  position: relative;
  left: 0;
  top: 0;
}

.Page10_p1 {
  position: absolute;
  top: 19%;
  left: 19%;
  width: 67vw;
  display: none;
}

@keyframes dash1 {
  to {
    stroke-dashoffset: 0;
  }
}

.dashed2 {
  stroke-dasharray: 10;
}

.path2 {
  stroke-dasharray: 4400;
  stroke-dashoffset: 4400;
  animation: dash2 4s linear alternate forwards;
}

@keyframes dash2 {
  to {
    stroke-dashoffset: 0;
  }
}

.svgeleven {
  left: -20%;
}


@media  (min-width: 320px) and (max-width: 426px) {
  .Req {
    width: 100%;
    height: 180vw !important;
    position: relative;
    padding: 0 0%;
    top: 0;
    left: 4%;
  }
  .svgsix {
    width: 100vw;
    height: 112vw;
    position: relative;
    left: -4%;
    top: 45%;
  }
  .svgseven {
    position: absolute;
    height: 104vw;
    width: 93vw;
    left: 0%;
    top: 51.5%;
  }
  .Page7_p1 {
    position: absolute;
    top: 61%;
    left: 7%;
    bottom: 20%;
    width: 81vw;
  }
  .Page7_p2 {
    position: absolute;
    top: 85%;
    left: 11%;
    width: 81vw;
    display: none;
  }
  .svgnine {
    width: 85vw;
    height: 100vw;
    position: absolute;
    z-index: -1;
    left: 4%;
    top: 56%;
  }
  .Page9_p1 {
    position: absolute;
    top: 66%;
    left: 6.5%;
    width: 85vw;
  }
  .Page6_p1 {
    position: absolute;
    top: 48%;
    left: 0%;
    width: 91vw;
  }
  .svgfour {
    width: 81vw;
    height: 116vw;
    top: 52%;
    left: 0%;
    z-index: -1;
    position: relative;
  }
  .Page4_p1 {
    position: absolute;
    top: 57% !important;
    width: 86vw;
    left: 3%;
    z-index: 1;
  }
  .Page4_p2 {
    width: 90vw;
    position: absolute;
    top: 78%;
    left: 2%;
    display: none;
  }
  .Page4_p3 {
    position: absolute;
    top: 95%;
    left: 6%;
    width: 84vw;
    display: none;
  }
  .svgthree {
    width: 85vw;
    height: 132vw !important;
    position: relative;
    left: -2%;
    top: 39%;
  }
  .Page3_p1 {
    position: absolute;
    top: 41%;
    left: 10%;
    width: 75%;
  }
  .Page3_p2 {
    position: absolute;
    top: 62%;
    left: 12%;
    width: 72%;
  }
  .Page3_p3 {
    position: absolute;
    top: 80%;
    left: 37%;
    width: 50%;
    display: none;
  }
  .svgpath {
    width: 100vw;
    height: 100vw !important;
    left: -2%;
    top: 34%;
    position: absolute;
  }
  .Page6_p2 {
    position: absolute;
    top: 62%;
    left: 21%;
    width: 65vw;
    display: none;
  }

  .Page6_p3 {
    width: 85vw;
    position: absolute;
    top: 88.8%;
    left: 5.2%;
    display: none;
  }
  .Page9_p2 {
    position: absolute;
    top: 86%;
    left: 5%;
    width: 89vw;
    display: none;
  }
  .svgeight {
    height: 100vw;
    width: 84vw;
    position: absolute;
    left: 3%;
    top: 45%;
  }
  .Page8_p1 {
    position: absolute;
    top: 56%;
    left: 3%;
    width: 87vw;
  }

  .Page8_p2 {
    position: absolute;
    top: 71%;
    left: 3%;
    width: 85vw;
    display: none;
  }
  
.svgone {
  width: 100vw !important;
  height: 109% !important;
  left: -1% !important;
  top: 11% !important;
  position: absolute !important;
}
  
  .Req_three {
    position: absolute;
    top: 75.5%;
    left: 9%;
    width: 75vw;
    display: none;
}
.Req_one {
  position: absolute !important;
  width: 77% !important;
  left: 9% !important;
  top: 33% !important;
}
.Req_two {
  position: absolute;
  top: 48.5%;
  left: 32%;
  width: 54vw;
  height: 33%;
  display: none;
}
.Page2_p1 {
  position: absolute;
  top: 30%;
  left: 10%;
  width: 82%;
}
.Page2_p2 {
  position: absolute;
  top: 54%;
  left: 35%;
  width: 55%;
  display: none;
}
.Page2_p3 {
  position: absolute;
  top: 73%;
  left: 7%;
  width: 82%;
  display: none;
}
.svgfive {
  height: 65vw;
  width: 77vw;
  position: relative;
  left: -4%;
  top: 56%;
  z-index: -1;
}
.Page5_p1 {
  position: absolute;
  top: 63%;
  left: 3%;
  width: 83vw;
}
.svgTen {
  width: 86vw;
  height: 104vw;
  position: relative;
  left: -4%;
  top: 49%;
}
.Page10_p1 {
  position: absolute;
  top: 54%;
  left: 2%;
  width: 92vw;
  display: none;
}
.Page10_p2 {
  position: absolute;
  top: 70%;
  left: 6%;
  width: 85vw;
  display: none;
}
.Page10_p3 {
  width: 77vw;
  position: absolute;
  top: 88%;
  left: 10%;
  display: none;
}
#groupE_img {
  position: relative;
  top: -1%;
}
}

