@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300&family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}