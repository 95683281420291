.swiper-pagination {
    z-index: -4 !important;
}

#slide_mobile {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s;
}

#slideRight_mobile {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s;
}

@media screen and (max-width:430px) {

    .MainSlider{
        height: 350px !important;
    }
    
}