.sectionDi {
  width: 100vw;
  height: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.content-sectionDi {
  width: 80%;
  padding: 10% 10%;

  font-size: 1.5vw;
}
.content-sectionDi > h1 {
  font-size: 3.3vw;
  font-weight: 400;
  margin-bottom: 5%;
  color: #3e5979;
  text-align: center;
}
.content-section > p {
  color: #3f4e66;

  width: 100%;
  font-size: 1.5vw;
  color: #3f4e66;
  text-align: center;
}
@media screen and (max-width: 426px){
  .content-section > p {
    
  
    width: 100%;
    font-size: 6vw;
    color: #3f4e66;
    text-align: center;
  }
  .content-sectionDi > h1 {
    font-size: 6vw;
  }
  .content-sectionDi {
    width: 102%;
    padding: 10% 10%;
    font-size: 4.1vw;
}
.sectionDi {

  height: 80vw;
}
}
@media screen and (min-width:320px)and (max-width: 426px) {
  .content-sectionDi {
    width: 107%;
    padding: 10% 10%;
    position: relative !important;
    top: -12vw;
    font-size: 4.5vw;
}
}