.Req {
  width: 100%;
  height: 88vw;
  position: relative;
  padding: 0 0%;
  top: 0;
  left: 4%;
}
.Req_onep {
  position: absolute;
  width: 50%;
  left: 14%;
  top: 15%;
  display: none;
}
.Req_twop {
  position: absolute;
  top: 54%;
  left: 19%;
  width: 50vw;
  height: 35%;
  display: none;
}
.svgone1 {
  width: 70vw;
  height: 70vw;
  left: 9%;
  top: 13%;
  position: absolute;
}
#box {
  display: none;
}
@media screen and (min-width: 319px) and (max-width: 425px) {
  .svgone1 {
    width: 112vw;
    height: 116vw;
    left: -10%;
    top: 3%;
    position: absolute;
  } 
  .Req_twop {
    position: absolute;
    top: 34%;
    left: 6%;
    width: 81vw;
    height: 35%;
    display: none;
  }
  .Req_onep {
    position: absolute;
    width: 85%;
    left: 5%;
    top: 6%;
    display: none;
  }
}