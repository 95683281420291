.Footer-main {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 111;
}

.boxContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.boxContainerInner {
    width: 100%;
    border: 3px solid #476390;
    background-color: #476390;
    margin-top: 15%;
    border-bottom-right-radius: 2rem;
}

.boxShadow {
    height: 200px;
    width: 471px;
    background: rgba(207, 97, 203, 0.4);
    transform: rotate(-30deg);
    filter: blur(77px);
}

.Footer {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: content-box;
}



.FristSection,
.SecondSection {
    width: 100%;
    height: auto;
}

.ThridSection {
    width: 70%;
}

.FourthSection {
    width: 90%;
}

.LogoSection {
    width: 95%;
    /* border: 2px solid red; */
    padding-bottom: 20%;
    float: right;
}

.FooterName {
    width: 250px;
    padding: 1rem;
    margin-left: 1rem;
}

.FooterName img {
    width: 100%;
}

.tagLine {
    width: 450px;
    padding-left: 2rem;
    position: relative;
}

.tagLine p {
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #3F4E66;
    text-align: start;
    padding-top: 1rem;
}

.InputTag {
    font-size: 15px !important;
    font-weight: 400 !important;
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #3F4E66;
    text-align: start;
    padding-left: 2rem;
}

.InputButton {
    width: 450px;
    padding-right: 3rem;
    margin-top: 3rem;
    position: relative;
}

.InputButton input {
    width: 350px;
    height: 50px;
    border-radius: 25px;
    border: 1.5px solid #8593A9;
    outline: none;
}

.InputButton button {
    position: absolute;
    right: 15%;
    width: 100px;
    height: 50px;
    border: 2px solid #3F4E66;
    border-radius: 46px;
    outline: none;
    background: #3F4E66;
    color: white;
    font-size: 20px;
}

/**Services**/
.Service-Section,
.CompanySection,
.GetInTouch {
    width: 100%;
    height: auto;
    /* border: 2px solid blanchedalmond; */
}

.Service-Section {
    padding-left: 15%;
}

.ServiceInner {
    width: 100%;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #3F4E66;
    text-align: left;
    /* border: 2px solid brown; */
}

.ServiceInner h4 {
    font-weight: 700;
    padding-left: 2rem;
}

/* .FourthSection{
    grid-column:  ;
} */

.ServiceInner ul {
    padding: 2rem 2rem;
    /* border: 2px solid red; */
}

.ServiceInner ul li {
    padding: 0.6rem 0rem;
    /* border: 2px solid black; */
    text-align: left;
}

.ServiceInner ul li a {
    text-decoration: none;
    color: #3F4E66;
}


.adj-icons {
    width: 100%;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    gap: 0.9vw;

}

.Footer-icons {
    width: 100%;
    /* border: 2px solid blue; */
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 2.5vw;
}

.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    background: #476390;
    color: #fff;
    align-items: center;
    justify-content: center;
}

.copyright {
    width: 100vw;
    /* width: -moz-available;           WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    min-height: 3.5vw;
    background-color: #476390;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.copyright div {
    width: 20vw;
    width: -moz-available;
    width: -moz-max-content;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    height: 1.5vw;
    text-align: center;
    font-size: 1vw;
    -webkit-width: 20vw;
}