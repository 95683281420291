.contentone-section {
  width: 60%;
  padding: 12% 10%;

  font-size: 1.5vw;
}
.contentone-section > h1 {
  font-size: 3.3vw;
  font-weight: 400;
  margin-bottom: 5%;
  color: #8a639a;
  text-align: center;
  position: relative;
  left: -2.8%;
}
.contentone-section > p {
  color: #3f4e66;

  text-align: center;
  width: 95%;
  font-size: 1.5vw;
}
.Page11_p1 {
  position: absolute;
  top: 27%;
  left: 6%;
  width: 68vw;
}
.Page11_p2 {
  position: absolute;
  top: 60%;
  left: 25%;
  width: 59vw;
  display: none;
}
.svgeleven {
  position: relative;
  width: 74vw;
  left: -4%;
  top: -5%;
  height: 100vw;
  z-index: -1;
}
.svgtwelve {
  position: absolute;
  width: 74vw;
  left: 9%;
  top: -5;
  height: 100vw;
}
.Page12_p1 {
  position: absolute;
  top: 26%;
  left: 6%;
  width: 68vw;
}
.Page12_p2 {
  position: absolute;
  top: 68%;
  left: 25%;
  width: 59vw;
  display: none;
}
.svgthirteen {
  position: absolute;
  width: 74vw;
  left: 9%;
  top: -6%;
  height: 100vw;
}

.Page13_p1 {
  position: absolute;
  top: 24%;
  left: 11.5%;

  width: 68vw;
}
.Page13_p2 {
  position: absolute;
  top: 48.9%;
  left: 35.5%;
  width: 39vw;
  display: none;
}
.Page14_p1 {
  position: absolute;
  top: 24%;
  left: 11.5%;
  width: 68vw;
}
.Page14_p2 {
  position: absolute;
  top: 56%;
  left: 18%;
  width: 60vw;
  display: none;
}
.svgfourteen {
  position: absolute;
  width: 74vw;
  left: 9%;
  top: -5%;
  height: 100vw;
}

contentone-section > p {
  font-size: 1.5vw !important;
}

@media (min-width: 320px) and (max-width: 426px) {
  .contentone-section {
    width: 100%;
    padding: 12% 10%;

    font-size: 3.9vw;
  }
  .contentone-section > h1 {
    font-size: 9vw;
    font-weight: 400;
    margin-bottom: 5%;
    color: #8a639a;
    text-align: center;
    position: relative;
    left: -2.8%;
  }
  .contentone-section > p {
    color: #3f4e66;
    text-align: center;
    width: 115%;
    font-size: 4.9vw;
    position: relative;
    left: -6%;
  }
  .svgeleven {
    position: relative;
    width: 93vw;
    left: -4%;
    top: 40%;
    height: 100vw;
    z-index: -1;
  }
  .Page11_p1 {
    position: absolute;
    top: 49%;
    left: 1%;
    width: 90vw;
  }

  element.style {
    display: block;
  }
  .Page11_p2 {
    position: absolute;
    top: 68%;
    left: 0%;
    width: 88vw;
    display: none;
  }
  .svgtwelve {
    position: absolute;
    width: 92vw;
    left: 1%;
    top: 41%;
    height: 100vw;
  }

  .Page12_p1 {
    position: absolute;
    top: 50%;
    left: 3%;
    width: 89vw;
  }

 
  .Page12_p2 {
    position: absolute;
    top: 72%;
    left: 2%;
    width: 87vw;
    display: none;
  }
  .svgthirteen {
    position: absolute;
    width: 91vw;
    left: 1%;
    top: 38%;
    height: 100vw;
  }
  .Page13_p1 {
    position: absolute;
    top: 47%;
    left: 4.5%;
    width: 84vw;
  }

 
  .Page13_p2 {
    position: absolute;
    top: 64%;
    left: 33.9%;
    width: 48vw;
    display: none;
  }

 
  .svgfourteen {
    position: absolute;
    width: 90vw;
    left: 2%;
    top: 37%;
    height: 100vw;
  }
  .Page14_p1 {
    position: absolute;
    top: 46%;
    left: 4.5%;
    width: 87vw;
  }
  .Page14_p2 {
    position: absolute;
    top: 64%;
    left: 9%;
    width: 82vw;
    display: none;
  }
}
