.purple {
    color: var(--imp-text-color) !important;
}

button:focus {
    box-shadow: none !important;
}

/* #no-scroll {
    overflow-x: hidden !important;
    height: 100vh;
} */

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
    background-color: #1b1a2ea9 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
    backdrop-filter: blur(15px) !important;
}

.Navbar_container {
    height: 5rem;
}

.navbar {
    width: 100%;
    padding-left: 5%;
    position: fixed !important;
    background-color: white !important;
    transition: all 0.3s ease-out 0s !important;
    padding-top: 1rem !important;
    font-size: 1.2rem !important;
    border-bottom: 2px solid #B7CFF7;
}

.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.navbar-toggler span {
    display: block !important;
    background-color: #be50f4 !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}



.listMenuBar {
    background: rgba(244, 246, 250, 0.5);
}



#mobileViewSolution ul {
    padding-left: 1rem !important;
    margin-bottom: 0rem !important;
}

#mobileViewSolution ul li {
    width: 100%;
    padding: 0.8rem;
    padding-left: 2rem;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #3865AD;
    position: relative;
}

.listMenuBar ul>li {
    font-family: Lato;
    padding: 0.5rem 0rem 0.4rem 1.7rem !important;
    font-size: 15px !important;
    font-weight: 300 !important;
    line-height: 24px;
    /* padding-left: 3rem !important; */
    letter-spacing: 0em;
    text-align: left;
    color: #3865AD;
}

.listMenuBar ul li a {
    text-decoration: none;
    color: #3865AD;
}

.mobileDropDown {
    position: absolute;
    top: 35%;
    right: 3%;
    z-index: -1;
}

.mobileborderBar {
    width: 100%;
    border: 1px solid #B7CFF7
}

#searchInput,
#MenuBar,
#slut,
#slutIndustries {
    display: none;
}

#slideIndustries {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s;
}

@media (max-width: 767px) {

    .logo {
        width: 80% !important;
    }

    .navbar {
        padding-left: 0 !important;
    }

    .navbar-nav {
        width: 100%;
        overflow-x: hidden;
        margin-left: 0 !important;
    }

    .navbar-nav .nav-item {
        border-bottom: 1px solid #E2EAF7;
        margin-left: 0px !important;
    }

    #searchInput {
        display: block;
        border: none;
        padding-bottom: 0.5rem;
        margin-left: 2rem;
        border-bottom: 2px solid #E2EAF7;
    }

    #slut {
        display: block;
        position: relative;
        width: 112%;
        left: -30px;
        z-index: 99;
        overflow-x: hidden !important;
        transition: height 0.2s linear;
    }

    #slutIndustries {
        display: block;
        position: relative;
        width: 112%;
        left: -30px;
        z-index: 99;
        overflow-x: hidden !important;
    }

    .iconMobile {
        position: relative;
        right: 25px;
        top: 0;
    }

    #MenuBar {
        display: block !important;
        border: none;
        padding-top: 1rem;
    }

    #MenuBar>.nav-link {
        padding: inherit !important;
        color: #929CAB !important;
    }

    #searchInput>input {
        width: 100%;
        border: none;
        padding-left: 10%;
        padding-bottom: 2%;
    }

    #searchInput>input:focus {
        outline: none;
    }

    #searchInput>input::placeholder {
        font-size: 16px;
        font-weight: 400px;
        color: #3865AD;

    }

    #Searchicon {
        position: absolute;
        left: 0;
        top: 10px;
    }

    .navbar-nav .nav-item a::after {
        display: none !important;
    }
}

/* .navbar-brand {
    color: rgb(250, 250, 250) !important;
} */

.logo {
    height: 100%;
    width: 90%;
}

.navbar-nav .nav-link {
    color: #3865AD !important;
    padding-right: 1rem !important;
    padding-left: 1rem;
}

.nav-link {
    padding: 0.8rem 1rem !important;
}

#Industrie:hover {
    cursor: pointer;
}

.mobileIcon {
    display: none;
}

#GETINTOUCH,
#MenuBar {
    display: none;
}


@media (max-width: 767px) {
    .nav-link {
        padding: 0.8rem 0rem !important;
    }

    .dropIcon {
        display: none;
    }

    .mobileIcon {
        display: inline-block;
        margin-right: 5%;
    }

    .mobileActive {
        font-weight: 800 !important;
        text-decoration: 2px underline;
    }

    .navbar-collapse {
        height: 480px;
        max-height: 630px;
        overflow: scroll;
        text-align: left;
        margin-top: 10%;
    }

    .login-btn {
        margin-left: 0px !important;
    }

    #GETINTOUCH {
        display: block;
        border-bottom: none;
    }

    .login-btn {
        display: none;
    }

    .Contactus {
        flex-wrap: wrap;
    }

    .TilteSection-Form p {
        padding: inherit !important;
    }

    .contactForm {
        width: inherit !important;
    }
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 10px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}

.navbar-nav .nav-item .nav-link.active {
    font-weight: 900;
}

.getIntouch {
    background: linear-gradient(90deg, #387FF1 10.16%, #CF61CB 128.04%) !important;
    font-weight: 800 !important;
}

.getIntouchText {
    color: #fff !important;
}

.navbar-nav .nav-item .nav-link.active::after {
    font-weight: 900;
    content: "";
    position: relative;
    display: block;
    height: 6px;
    width: 100%;
    background: #CF61CB;
    bottom: 1px;
    left: -1%;
    top: 25px;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item ::after {
    content: "";
    position: relative;
    display: block;
    height: 6px;
    width: 0;
    background: #CF61CB;
    bottom: 1px;
    left: -1%;
    top: 25px;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item :hover::after {
    width: 100%;
}

#DropDown {
    display: none;
}

.DdFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3865AD;
}

.DdFlex:hover {
    cursor: pointer;
}

.DropDown {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    background-color: #ffff;
    z-index: 999;
}

.DropDownCard {
    padding: 2.5vw 8.8vw;
    z-index: 1;
    width: 100%;
    clip-path: inset(0vw -1vw -1vw -1vw);
    box-shadow: 0vw 0.2vw 1vw 0vw #71717140;
    border-radius: 0vw 0vw 2vw 2vw;
    background: white;
    position: relative;
    top: 6.5vw;
    background-color: #ffff !important;
}

.navbar-nav {
    margin-left: 10%;
}

.content_items {
    width: 100%;
    color: #465154;
    text-align: left;
}

#BOMConfiguration {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

#BOMConfiguration>img {
    width: 25px;
    height: 25px;
    margin-left: 0.2rem;
    margin-top: 0.3rem;
}

/*.content_item{
    border: 2px solid black;
}
*/
.dropIcon {
    position: absolute;
    right: -3px;
    top: 25px;
    z-index: 1;
    transition: all 0.2s linear;
}

.roterIcon {
    transition: all 0.2s linear;
    transform: rotate(180deg);
}

.content-heading {
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    padding-left: 0.9rem;
    text-align: left;
    color: #8A99B1;
}

.list_drop {
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    row-gap: 1vw;
}

/*DropDown*/
.itemContent {
    width: 250px;
    display: flex;
    justify-content: space-evenly;
    padding: 0.9rem 0.5rem;
    /* border: 2px solid red; */
}

.itemContent:hover {
    background: rgba(244, 246, 250, 0.5);
}

.activehover {
    background: rgba(244, 246, 250, 1);
}

.iconCricle {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(160, 160, 160, 0.25);
}

.iconCricle img {
    width: 15px;
    height: 15px;
    margin-left: 0.3rem;
    margin-top: 0.2rem;
}

.processIcon img {
    margin-top: -0.1rem !important;
}

.item-title {
    width: 100%;
    padding-left: 0.5rem;
    padding-top: 0.2rem;
}

.item-title a {
    padding-left: 5px;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    color: #3865AD;
}

.list-items {
    display: block;
    text-decoration: none;
    padding: 0.3rem;
    margin-top: 0.2rem;
    font-size: 15px;
    color: #3865AD;
    font-weight: 400;
    opacity: 0.8;
}

.list-items:hover {
    text-decoration: 2px solid underline rgba(244, 246, 250);
    text-underline-offset: 2px;
}

@media (min-width:512px) and (max-width:1024px) {
    .login-btn {
        padding-top: 3px !important;
        margin-left: 42px !important;
        margin-right: 30px !important;
    }

    .navbar-nav {
        margin-left: 4% !important;
    }

    .dropIcon {
        top: 23px !important;
    }

    .home_inner {
        height: auto !important;
    }
}

@media (min-width:1200px) and (max-width:1499px) {

    .cricleMain {
        top: -9% !important;
        left: 18% !important;
    }

    .cricleMain_even:nth-child(2n) {
        left: 8% !important;
        bottom: -13% !important;
    }

    .box1 .input-container button {
        height: 3.5vw !important;
    }

    .StyleFont span {
        right: 16% !important;
    }

}

@media (min-width:1400px) and (max-width:2000px) {
    .cricleMain {
        top: 0 !important;
        left: 23% !important;
    }

    .cricleMain_even:nth-child(2n) {
        left: 12% !important;
        bottom: 0 !important;
    }

    .rectangleImg {
        right: 14% !important
    }
}

@media (min-width:1024px) and (max-width:1200px) {
    .navbar {
        font-size: 18px !important;
        padding-left: 0% !important;
    }

    .navbar>.container {
        padding-left: 0 !important;
    }

    .login-btn {
        margin-left: 25px !important;
    }

    .loginNav {
        width: 120px;
    }
}