.sliderMainMiddel {
    width: 100%;
    height: 100%;
}

.slider_middleHome_right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.slider_middleinner_right {
    width: 90%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
}

.customSlider {
    width: 100%;
    height: 100%;
    background: #F5F9FE;
    position: relative;
    border-radius: 29px 0px 0px 29px;
}
.customSlider-right{
    border-radius: 0px 29px 29px 0px;
}

.Navigation {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: 10%;
    z-index: 1;
}
.Navigation_left{
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2%;
}
.Navigation_left svg{
    transform: rotate(-180deg);
}
#ArrowButton {
    width: 52px;
    height: 30px;
}

#ArrowButton:hover{
    padding: 1px;
    cursor: pointer;
    background: #F5F9FE;
    box-shadow: 0px 2px 3px 0px #97979740;
}

.middelContent {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    z-index: -1;
}

.middelContent .guideText {
    width: 70%;
    display: flex;
    justify-content: start;
    align-items:flex-start;
    padding-top: 8rem;
    padding-left: 15vw;
}

.slider_middleHome_left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.slider_middleinner_left {
    width: 90%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
   /* background: #F5F9FE;*/
    border-radius: 29px;
}

.slider_middleinner_right .swiper {
    margin-left: 10%;
}

.slider_middleinner_left .swiper {
    margin-right: 10%;
}

.cardslider {
    padding: 4.5rem 0rem;

}

.slider_middleinner_left .CardInner p{
    margin-top: 1rem;
}
.CardInner {
    width: 370px;
    height: 190px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textFont {
    font-family: 'Lato';
    font-size: 28px;
    font-weight: 300;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F4E66;
}
.guideText .textFont{
    font-weight: 400;
}

.backgroundColor {
    height: 230px;
    width: 30%;
    background: #387FF166;
    filter: blur(77px);

}

.CardInner p {
    padding: 1rem;
}

/* .slider_middleinner_left .CardInner p {
    padding: 2rem 0rem;
} */

.border-gradient {
    border-width: 2px;
    border-radius: 15px 0px 15px 0px;
    border: double 2px transparent;
    background-image: linear-gradient(white, white),
        linear-gradient(180deg, #387FF1 0%, #CF61CB 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;

}
.border-gradient-2nd {
    border-width: 2px;
    border-radius: 15px 0px 15px 0px;
    border: double 4px transparent;
    background-image: linear-gradient(white, white),
        linear-gradient(180deg, #387FF1 0%, #CF61CB 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;

}
.iconOuter {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -25%;
    left: 0;
}

.iconInner {
    width: 60px;
    height: 60px;
}