body {
  padding: 0;
  margin: 0;
}

.section4 {
  width: 100%;
  height: 58vw;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content-section4 {
  width: 58vw;

  left: 22%;
  padding: 10% 10%;
  position: relative;
  font-size: 1.5vw;
  padding: 5%;
}

.content-section4>h1 {
  font-size: 3.3vw;
  font-weight: 390;
  margin-bottom: 5%;
  color: #dcac00;
  text-align: center;
  font-weight: 400;
  position: relative;
  text-align: start;
}

.boldSpan {
  font-weight: 700;
}

.image-section4 {
  width: 100vw;
  z-index: -1;
  position: absolute;
}

.image-section4>img {
  width: 100%;
}

.content-section>p {
  color: #3f4e66;

  width: 100%;
  font-size: 1.5vw;
}

.imgIntegrationDi14 {
  display: flex;
  position: relative;
  text-align: start;
  text-align-last: start;
}

.imgIntegrationDi14>img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.3vw;
  left: -10%;
  top: 10%;
  position: absolute;
}

.imgIntegrationDi14>p {
  padding: 1.3%;
  position: relative;
  left: -1%;
}

.pink {
  color: #b54662 !important;
}

.darkgreen {
  color: #4a8461 !important;
}

.darkblue {
  color: #4c3a82 !important;
}

.content-sectionplmtwoDi14 {
  width: 37vw;

  font-size: 1.5vw;

  left: 5%;
}

/*@media screen and (max-width: 426px){
  .content-sectionplmtwoDi14 {
    width: 84vw;
    font-size: 4vw;
    position: relative;
   left: 4%; 
}
content-section4 {
  width: 87vw;
  
  
  position: relative;
  
  
} 
.section4 {
  height: 100vh;
}
.content-section4 {
  width: 63vw;
  left:  -14%;
  
  position: relative;
  font-size: 4vw;
 padding: 5%; 
}
.content-section4 > h1 {
  font-size: 7.1vw;
  
}
.image-section4 {
  width: 99vw;
  z-index: -1;
  position: absolute;
  opacity: 0.2;
  
}
.image-section4 > img {
  width: 300%;
  left: 3%;
  position: relative;
  
}
.image-sectionplmone {
  width: -2%;
  position: absolute;
  
}
}*/

@media screen and (min-width: 320px) and (max-width: 430px) {

  #slideIndustrie {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s;
  }

  .SwiperJs>.sliderData {
    padding-top: 0rem !important;
    padding-left: inherit !important;
    margin-right: inherit !important;
    position: inherit !important;
    width: 100vw;
  }

  .SwiperJs>.sliderData .MainSlider {
    width: 100% !important;
    height: 550px !important;
  }

  .section4 {
    width: 100% !important;
    height: inherit !important;
    display: block !important;
    position: inherit !important;
  }

  .section4>.image-section4 {
    display: none;
  }

  .section4>.content-section4 {
    width: 100% !important;
    position: inherit !important;
    padding-left: 15% !important;
  }

  .imgIntegrationDi14>img {
    width: 24px !important;
    top: 3% !important;
  }

  .content-section4>h1 {
    font-size: 35px !important;
    font-weight: 800 !important;
  }

  .content-section4>.content-sectionplmtwoDi14 {
    width: 100% !important;
    font-size: 18px !important;
  }


}

@media screen and (min-width: 426px) and (max-width: 768px) {
  /* #swperprocess .swiper,
  #swperdd .swiper {
    overflow: inherit !important;
    padding: 0;
    z-index: 1;
    height: 100vh;
  }*/
}